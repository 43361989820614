import { Component, OnInit, ViewChild } from '@angular/core';

import { GMapModule } from 'primeng/primeng';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GooglemapComponent } from '../../shared/googlemap/googlemap.component';

@Component({
  selector: 'app-m-ubicacion',
  templateUrl: './m-ubicacion.component.html',
  styleUrls: ['./m-ubicacion.component.scss']
})
export class MUbicacionComponent implements OnInit {

  @ViewChild(GooglemapComponent) googleMap: GooglemapComponent;

  closeBtnName: string;
  title: string;
  puntos: any [];

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {

  }

  verMiUbicacion() {
    this.googleMap.setMap(this.puntos);
  }
}
