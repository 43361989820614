
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Gtp } from '../../../../constants/gtp';
import { Constant } from '../../../../constants/constant';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CheckoutMovilDocumentoService {

    public baseUrl: string = '';

    constructor(public http: Http,
        public gtp: Gtp,
        public constant: Constant) {
        this.baseUrl = environment.urlApiCheckoutMovil;
    }

    guardarVehiculoDocumento(body: any): Observable<any> {
        return this.http.post(`${this.baseUrl}vehiculo/documento/new`, body, this.constant.options)
            .map(response => response.json());
    }
}