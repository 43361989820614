//angular
import { Routes, RouterModule } from '@angular/router';
import { GruposReferenciasComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const gruposReferenciasRoutes: Routes = [
    {
        path: 'herramienta/grupo/referencia',
        children: [
            {
                path: 'lista',
                component: GruposReferenciasComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const gruposReferenciasRouting = RouterModule.forChild(gruposReferenciasRoutes);