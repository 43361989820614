import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MapIcon } from './map.icon';
import { AgmCoreModule } from '@agm/core/core.module';
import { Gtp } from '../../constants/gtp';

declare var google: any;

//https://stackoverflow.com/questions/46793695/add-button-into-google-maps-angular2
//http://plnkr.co/edit/lMA3Tnu6k4oJLOfnZ40B?p=preview

@Component({
    selector: 'app-googlemap',
    templateUrl: './googlemap.component.html',
    styleUrls: ['./googlemap.component.scss']
})
export class GooglemapComponent implements OnInit {

    @Output() OutMap = new EventEmitter<any>();
    @Input() polyline: boolean;
    @ViewChild('infowindow') infoWindow: AgmCoreModule;

    //Config Mapa  
    headerHeight: any = 86;  //Tamaño Header
    mapHeight: any;          //Tamaño Mapa

    //Datos Posición latitud y longitud
    latCenter: any;
    lngCenter: any;

    // lista de puntos para los marcadores
    markers: any;
    //lista de puntos para los polylines
    polylines: any;          //Polylines  
    zoomMap: number = 9;//13; //Zoom del Mapa

    verflota: any = false;   //Carga Flota de Mobiles

    isClicked: boolean;
    selectedLocation: Location;
    // va a almacenar el objeto vehículo.
    vehiculo: any;

    //Instancia GoogleMap
    _gmap: any;
    iconMap: any;

    constructor(
        public mapIcon: MapIcon,
        public gtp: Gtp) {
        this.mapHSize();
    }
    ngOnInit() {
        let that = this;
        window.onresize = function () { that.mapHSize(); };
        //recuper el vehículo del localstorage si existe, sino null
        this.vehiculo = this.gtp.getItemLocalStorage('vehiculo') ? JSON.parse(this.gtp.getItemLocalStorage('vehiculo')) : null;
    }
    //Altura del mapa
    mapHSize() {
        this.mapHeight = window.innerHeight - this.headerHeight;
    }
    //Centra el mapa basado en la cantidad de puntos
    mapCenter() {
        let total = this.markers.length; //Total de marcadores
        let mitad = Math.ceil(total / 2) - 1; // se obtiene la mitad, -1 por la posición en la lista
        let medio = (total > 1) ? mitad : 0; // se determina el valor medio.
        //se obtiene el registro en la posición medio de la lista de marcadores, esto para poder centrar en el mapa
        let ub = this.markers[medio]; //Traigo el marcador
        // como se reutiliza el componente para ver flota y para recorrido los nombres de los campos no son iguales, por tanto se pregunta si la variable no es undefined
        if (ub.ult_latitud) {
            this.latCenter = ub.ult_latitud;
        } else {
            this.latCenter = ub.latitud;
        }
        if (ub.ult_longitud) {
            this.lngCenter = ub.ult_longitud;
        } else {
            this.lngCenter = ub.longitud;
        }
        // llama a la función para centrar en el mapa
        this.setCenter(parseInt(this.latCenter), parseInt(this.lngCenter));
    }
    /**
     * Función para centrar en el mapa el punto con latitud y longitud recibido como parámetro
     * @param lat valor de latitud del punto
     * @param long valor de longitud del punto
     */
    setCenter(lat, long) {
        this._gmap.setCenter({ lat: lat, lng: long });
    }

    //Instancia de GoogleMap - Corre cuando carga la libería del Mapa
    setInstance(map) {
        this._gmap = map;
        this._gmap.setTilt(0);
        this.iconMap = this.mapIcon.getIcon(20, 'auto', 'activo');
        this.OutMap.next(true);
    }

    /**
     * Función para popular el mapa con los puntos pasados como parámetro y mostrarlos con sus respectivos marcadores.
     * @param puntos lista de puntos para popular el mapa.
     */
    setMap(puntos) {
        let listmarker = [];
        let estado;
        let latitud;
        let longitud;
        let direccion;
        let velocidad;
        let fechaHora;
        let icono;
        if (puntos.length > 0) {
            // se itera por cada registro de la lista.
            puntos.forEach((row, i) => {
                if (row.ult_latitud) {
                    latitud = row.ult_latitud;
                } else {
                    latitud = row.latitud
                }
                if (row.ult_longitud) {
                    longitud = row.ult_longitud;
                } else {
                    longitud = row.longitud
                }
                if (row.ult_direccion) {
                    direccion = row.ult_direccion;
                    icono = 'auto';
                } else {
                    direccion = row.direccion;
                    icono = 'flecha';
                }
                if (row.ult_velocidad) {
                    velocidad = row.ult_velocidad;
                } else {
                    velocidad = row.velocidad
                }
                if (row.ult_fecha_hora) {
                    fechaHora = row.ult_fecha_hora;
                } else {
                    fechaHora = row.fecha_hora
                }
                row.latitud = parseFloat(latitud);
                row.longitud = parseFloat(longitud);
                row.direccion = direccion;
                row.velocidad = velocidad;
                row.fecha_hora = fechaHora;
                if (row.des_equipo_ratreo) {
                    row.label = {
                        color: '#e81cdf',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        text: row.des_equipo_ratreo
                    };
                } else {
                    row.label = null;
                }
                if (parseFloat(velocidad) > 0) {
                    estado = 'activo';
                } else {
                    estado = 'inactivo'
                }
                if (i == 0) {
                    icono = 'auto';
                }
                row.icono = this.mapIcon.getIcon(parseFloat(direccion), icono, estado);
                row.cod_equipo_rastreo = row.cod_equipo_rastreo ? row.cod_equipo_rastreo : this.vehiculo.cod_equipo_rastreo;
                listmarker.push(row);
            });
        } else {
            let r = {latitud: -25.2719623, longitud: -57.59353369999999};
            listmarker.push(r)
        }
        this.markers = listmarker;
        // si polyline es true se marca en el mapa la linea que une dos puntos, por Ej: para reflejar el recorrido del móvil
        if (this.polyline) {
            this.polylines = listmarker;
        }
        // llama al método para centrar el mapa en el punto medio de todos los puntos.
        this.mapCenter();
    }

    updateDiv($event) {
        this.isClicked = true;
        //this.selectedLocation = location;
        console.log($event)
    }

}