import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';


@Component({
    selector: 'app-ubicar-calles',
    templateUrl: '../templates/ubicarCalles.component.html'
})
export class UbicarCallesComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    busquedaActiva: boolean = false;
    model: any = {};

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Ubicar Calles`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `ubicarCalles`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'calle1',
                data: 'calle1',
                title: "Calle 1",
                width: "20%",
                className: "dt-center"
            },
            {
                name: 'calle2',
                data: 'calle2',
                title: "Calle 2",
                width: "20%"
            },
            {
                name: 'pais',
                data: 'pais',
                title: "Pais",
                width: "15%"
            },
            {
                name: 'departamento',
                data: 'departamento',
                title: "Departamento",
                width: "15%"
            },
            {
                name: 'ciudad',
                data: 'ciudad',
                title: "Ciudad",
                width: "15%"
            },
            {
                name: 'opcion',
                orderable: false,
                title: "Opción",
                width: "15%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button class="btn buttons-html5 color-dark-blue btn-accion btn-round">
                        <i class="fas fa-map-marker-alt"></i> 
                        <span class="label-action">GoogleMap</span>
                    </button>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Ubicar Calles`);
        this.model = {};
    }

    ngOnInit() {
        
    }

    bucarCalle() {
        this.busquedaActiva = true;
        this.obtenerArchivosCargados();
    }

    obtenerArchivosCargados() {
        this.tabla.setData(this.configTable);
    }
}
