//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapModule } from '../../../shared/googlemap/googlemap.module';
import { InformeComponent } from './components';
import { informeRouting } from './informe.routing';
import { CalendarModule, GMapModule } from 'primeng/primeng';
import { TablaModule } from '../../../shared/infotabla/infoTabla.module';
import { MUbicacionModule } from '../../../mapa/m-ubicacion/m-ubicacion.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        informeRouting,
        GoogleMapModule,
        GMapModule,
        CalendarModule,
        TablaModule,
        MUbicacionModule
    ],
    declarations: [
        InformeComponent
    ],
    providers: [
        
    ],
    exports: [
        InformeComponent
    ]
})

export class InformeModule { }