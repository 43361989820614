import { Component, OnInit, ViewChild } from '@angular/core';

//Servicios
import { SidepanelsService } from '../../rastreo/services/sidepanels.service';
import { RastreoService } from '../../services/rastreo.service';
import { Gtp } from '../../constants/gtp';
import { AuthenticationService } from '../../rastreo/login/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('header') header: any;
  fechaActual: string;
  horaActual: string;
  interval: any;
  ultimoAcceso = new Date();

  constructor(
    public _rastreo: RastreoService,
    public _panel: SidepanelsService,
    public authenticationService: AuthenticationService,
    public gtp: Gtp
  ) { }

  ngOnInit() {
    //this._rastreo.hHeight = this.header.nativeElement.offsetHeight;   
    //se obtiene la fecha actual para mostar en la cabecera
    this.fechaActual = this._rastreo.obtenerFechaActual();
    //se obtiene la hora en tiempo real en un intérvalo de cada 1 segundo
    this._rastreo.obtenerHoraEnTiempoReal('reloj');
    this.interval = setInterval(() => {
      this._rastreo.obtenerHoraEnTiempoReal('reloj');
    }, 1000);
  }

  //Abrir / Cerrar Panel Left
  tPanelIzq() {
    this._panel.tPanelIzq();
  }

}
