export class Geocerca {
    id: number;
    nombre: string;
    entrada: boolean;
    salida: boolean;
    maxParada: boolean;
    valorMaximaParada: number
    maxVelocidad: boolean;
    valorMaxVelocidad: number;
    activar: boolean;

    constructor() {
        this.entrada = false; 
        this.salida = false;
        this.maxParada = false;
        this.valorMaximaParada = 0; 
        this.maxVelocidad = false;
        this.valorMaxVelocidad = 0; 
        this.activar = false;
    }
}