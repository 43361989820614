import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { RastreoService } from '../../../../services/rastreo.service';
import { Gtp } from '../../../../constants/gtp';
declare const $;

@Component({
  selector: 'app-checkout-movil-historial-detalle',
  templateUrl: '../templates/checkoutMovilHistorialDetalle.component.html'
})
export class CheckoutMovilHistorialDetalleComponent implements OnInit {

  @ViewChild(TablaComponent) tabla: TablaComponent;

  openTabs: any = false;
  showFirst: any = true;

  hhasta;
  hdesde;
  vehiculoId: any;

  // máxima fecha para la fecha de chequeo
  maxFechaActualizacion = new Date();
  fechaActualizacion: any;
  //Datos de Lenguaje del DateTime Picker
  fechalang: any;

  configTable: any = {
    //Nombre del archivo a Exportar
    filename: `Detalle de Historial de Checkout Movil ${this.vehiculoId}`,
    //Usuario Genero PDF
    user_g: "",
    //Totales top
    headerPdf: ``,
    //Link endPoint
    endpoint: `vehiculo/item/puntuado`,
    //Configuración de columnas    
    t_columns: [
      {
        name: 'cliente_vehiculo_item_nombre',
        data: 'cliente_vehiculo_item_nombre',
        title: "Ítem",
        width: "30%",
        className: "dt-center",
      },
      {
        name: 'cliente_vehiculo_item_estado',
        title: "Observación",
        width: "30%",
        render: function (data: any, type: any, row: any, meta) {
          let icon = 'ui-1_check';
          let color = 'color-success';
          let obs = 'Ítem OK!';

          if (parseInt(row.item_puntuacion_valor) < parseInt(row.item_puntaje_minimo)) {
            icon = 'ui-1_simple-remove';
            color = 'color-danger';
            obs = 'Puntuación de ítem menor al mínimo permitido.'
          }
          let html;
          html = `<span>${obs}</span><span class="ml-10"><i class="now-ui-icons ${icon} ${color} icon-round-table"></i></span>`;
          return html;
        }
      },
      {
        name: 'item_puntuacion_valor',
        data: 'item_puntuacion_valor',
        title: "Puntuación",
        width: "10%"
      },
      {
        name: 'fecha_actualizacion',
        title: "Fecha de Actualización",
        width: "15%",
        className: "dt-center",
        render: function (data: any, type: any, row: any, meta) {
          let html;
          html = `<span>${Gtp.formatearFechaHora(row.fecha_actualizacion)}</span>`;
          return html;
        }
      },
      {
        name: 'usuario_modificador_username',
        data: 'usuario_modificador_username',
        title: "Usuario Modificador",
        width: "15%",
        className: "dt-center"
      }
    ],
    // Columnas a exportar
    exp_columns: [0, 1, 2, 3],
    // Columa que debe llevar al header
    htarget: null,
    // Columna con botones de acciones
    actionbtn: null,
    // Agrupa o no (Deja un header arriba)  
    agrupar: false,
    filtro: {},
    tablaId: ''
  }
  constructor(public _rastreo: RastreoService,
    public gtp: Gtp) {
    //Trae la fecha con idioma español
    this.fechalang = this._rastreo.fechalang;
    this.vehiculoId = JSON.parse(gtp.getItemLocalStorage('vehiculoId'));
    console.log(this.vehiculoId);
    this.configTable.filtro.vehiculo_id = this.vehiculoId;
    this.configTable.tablaId = this.vehiculoId;
    //Trae la fecha con idioma español
    this.fechalang = this._rastreo.fechalang;
  }

  ngOnInit() {
    this.obtenerItemsPuntuados(1);
  }

  obtenerItemsPuntuados(pagina) {
    this.configTable.filtro.pagina = pagina;
    this.configTable.filtro.fecha_actualizacion = this.fechaActualizacion;
    this.tabla.setData(this.configTable);
  }
}
