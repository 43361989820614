import { Component, OnInit } from '@angular/core';

//Servicios
import { RastreoService } from '../../../services/rastreo.service';
import { SidebarService } from '../../services/sidebar.service';

/*
s-menu: Sidebar Menu Flota
*/

@Component({
  selector: 's-menu',
  templateUrl: './s-menu.component.html',
  styleUrls: ['./s-menu.component.scss']
})
export class SMenuComponent implements OnInit {

  menuFlota:any;

  constructor(
    private _rastreo:RastreoService,
    private _sidebar:SidebarService
  ){
    this.getMenuFlota();
  }

  ngOnInit() {
  }

  getMenuFlota(){
    this._sidebar.fetchMenuFlota().subscribe(
      res=> {
        this.menuFlota = res;
      },
      //Error
      err => { 
        //console.log(err); 
      },
      //Terminó de cargar
      () => {  }
    );    
  }

}
