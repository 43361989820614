import { Injectable } from '@angular/core';
import { Http, Response} from "@angular/http";
import { Observable } from 'rxjs/Observable';
//import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';

import { environment } from '../../environments/environment';
import { VehiculoLista } from '../rastreo/models/vehiculo-lista.model';

@Injectable()
export class VehiculosService {

	public baseUrl:any = environment.baseUrl;
	//private smoviles = new BehaviorSubject(null);
	//lista_moviles = this.smoviles.asObservable();
	lista_moviles:any;
	
	constructor(
		private http: Http
	){}

  	//Trae listado de Móviles
	fetchLVehiculos():Observable<VehiculoLista[]>{
		return this.http.get(`${this.baseUrl}/lista-vehiculos.json`)
		.map( (res: Response) => res.json() )		
	}
  
	getVehiculos(){
		this.fetchLVehiculos().subscribe(
			res=> {
				//this.smoviles.next(res);
				this.lista_moviles = res;
				return res;
			},
			//Error
			err => {
				console.log(err); 
			},
			//Terminó de cargar
			() => {}
		); 
	}

}
