/*
Controla los Paneles en visibilidad / Tamaños:
Izquierdos, Derechos y la Caja Central
*/

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from "rxjs"

import { RastreoService } from '../../services/rastreo.service';

@Injectable()
export class SidepanelsService {

  sidepanel:any = {
    left: {
      state     : true
    },
    main: {
      state     : true,
      default   : 'col-sm-12 p-0',
      col1      : 'col-lg-12 col-md-12',
      col2      : 'col-lg-12 col-md-12',
      col3      : 'col-lg-8 col-md-8'
    },
    right: {
      state     : false
    },
    top: {
      state     : true
    }
  };

  mainClass = new Subject<any>();

  constructor(
    private _rastreo:RastreoService
  ){
    
  }

  getPanelInfo(){
  	this.sidepanel.main.col1 = this.sidepanel.main.col1+" "+this.sidepanel.main.default;
    this.sidepanel.main.col2 = this.sidepanel.main.col2+" "+this.sidepanel.main.default;
    this.sidepanel.main.col3 = this.sidepanel.main.col3+" "+this.sidepanel.main.default;
    return this.sidepanel;
  }

  //Default Size Panels
  setPanelSizes(){    
    if(!this.sidepanel.left.state && !this.sidepanel.right.state){ 
      this.mainClass.next(this.sidepanel.main.col1);
    }
    if((!this.sidepanel.left.state && this.sidepanel.right.state) || (this.sidepanel.left.state && !this.sidepanel.right.state)){
      this.mainClass.next(this.sidepanel.main.col2);
    }
    if(this.sidepanel.left.state && this.sidepanel.right.state){ 
      this.mainClass.next(this.sidepanel.main.col3);
    }
  }

  getMainClass(): Observable<any>{
    return this.mainClass.asObservable();
  }

  //Abrir / Cerrar Panel Left
  tPanelIzq(){
    if(this.sidepanel.left.state){
        this.sidepanel.left.state = !this.sidepanel.left.state;
        this.mainClass.next(this.sidepanel.main.col2);
    } else {
      this.sidepanel.left.state = true;
    }
    this.setPanelSizes();
  }

  //Abrir / Cerrar Panel Right
  tPanelDer(){
    if(this.sidepanel.right.state){
        this.sidepanel.right.state = !this.sidepanel.right.state;
        this.mainClass.next(this.sidepanel.main.col2);
    } else {
      this.sidepanel.right.state = true;
    }
    this.setPanelSizes();
  }

  //Abrir / Cerrar Panel Right
  tPanelHeader(){
    if(this.sidepanel.top.state){
        this.sidepanel.top.state = !this.sidepanel.top.state;
    } else {
      this.sidepanel.top.state = true;
    }
  }

}
