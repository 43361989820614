//angular
import { Routes, RouterModule } from '@angular/router';
import { ImportarPuntosComponent, ImportarPuntosGPXComponent, ImportarPuntosXLSRoadshowComponent, UbicarCallesComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const puntosRoutes: Routes = [
    {
        path: 'herramienta/punto',
        children: [
            {
                path: 'importar/lista/xls',
                component: ImportarPuntosComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'importar/lista/gpx',
                component: ImportarPuntosGPXComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'importar/lista/roadshow/xls',
                component: ImportarPuntosXLSRoadshowComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'ubicar/calle',
                component: UbicarCallesComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const puntosRouting = RouterModule.forChild(puntosRoutes);