import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { RastreoService } from '../../../../services/rastreo.service';
import { Geocerca } from '../../../models/geocerca';
declare const $;

@Component({
  selector: 'app-chofer-personalizar',
  templateUrl: '../templates/choferPersonalizar.component.html'
})
export class ChoferPersonalizarComponent implements OnInit {

  @ViewChild(TablaComponent) tabla: TablaComponent;

  openTabs: any = false;
  showFirst: any = true;

  fechalang: any;
  hhasta;
  hdesde;
  ciVencimiento;
  rcVencimiento;
  geocercaList = [
    {id: 1, nombre: 'Taller Vidal', entrada: false, salida: false, maxParada: false, valorMaximaParada: 0, maxVelocidad: false, valorMaxVelocidad: 0, activar: false}
  ]

  constructor(public _rastreo: RastreoService) {
    //Trae la fecha con idioma español
    this.fechalang = this._rastreo.fechalang;
  }

  ngOnInit() {

  }

}
