import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';

@Injectable()
export class Constant {

    public message: string = 'Ocurrió un error inesperado. Inténtelo de nuevo en unos minutos.'
    public headers: Headers;
    public options: RequestOptions;
    public accessToken: string;

    public VERSION = '1.0.0';
    //títulos
    public TITLE_ADVERTENCE = 'Advertencia!';
    public TITLE_DELETE_SUCCESS = 'Eliminación de Registro!';
    public TITLE_SUCCESS = 'Registro Exitoso!';

    //mensajes
    public MSG_DELETE_SUCCESS = 'Registro eliminado con éxito.';
    public MSG_SUCCESS = 'Los datos se registraron correctamente.'

    constructor(public http: Http, public router: Router) {
        
    }
    setHeaders(accessToken: string) {
        this.accessToken = accessToken;
        this.headers = new Headers();
        this.headers.set( 'Content-Type', 'application/json' );
        this.headers.set( 'Authorization', `Bearer ${this.accessToken}` );
        this.options = new RequestOptions({ headers: this.headers });
    }
}