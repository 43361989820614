import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
//constants
import { ToastrService } from 'ngx-toastr';
import { Constant } from '../../../constants/constant';
@Injectable()
export class AlertService {
    public subject = new Subject<any>();
    public keepAfterNavigationChange = false;
    public messageError: string;

    constructor(public router: Router, public constant: Constant, public toastr: ToastrService) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, title: string, keepAfterNavigationChange = false) {
        this.toastr.success(message, title);
    }

    error(error: string, keepAfterNavigationChange = false) {
        let errorJSON = JSON.parse(error);
        console.log(errorJSON);
        switch (errorJSON.status) {
            case 400:
                this.messageError = this.constant.message;
                break;
            case 500:
                this.messageError = this.constant.message;
                break;
            case 502:
                this.messageError = this.constant.message;
                break;
                case 404:
                this.messageError = this.constant.message;
                break;
            case 0:
                this.messageError = this.constant.message;
                break;
            default:
                this.messageError = JSON.parse(errorJSON._body).message;
                break;
        }

        this.alert(this.messageError);
    }

    alert(message: string, keepAfterNavigationChange = false) {
        this.toastr.error(message, this.constant.TITLE_ADVERTENCE);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}