import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';


@Component({
    selector: 'app-usuarios-roadshow',
    templateUrl: '../templates/usuariosRoadshow.component.html'
})
export class UsuariosRoadshowComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Usuarios Roadshow`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `usuariosRoadshow`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'usuario',
                data: 'usuario',
                title: "Usuario",
                width: "15%",
                className: "dt-center"
            },
            {
                name: 'nombre',
                data: 'nombre',
                title: "Nombre",
                width: "15%"
            },
            {
                name: 'apellido',
                data: 'apellido',
                title: "Apellido",
                width: "15%"
            },
            {
                name: 'email',
                data: 'email',
                title: "Email",
                width: "15%"
            },
            {
                name: 'activo',
                data: 'activo',
                title: "Activar",
                width: "15%",
                orderable: false,
                render: function (data: any, type: any, row: any, meta) {
                    var html = `
                      <div class="form-check">
                      <label class="form-check-label">
                          <input class="form-check-input" checked="${row.estado}" value="${row.estado}" [(ngModel)]="${row.estado}" type="checkbox">
                          <span class="form-check-sign"></span>
                      </label>
                  </div>`;
                    return html;
                }
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "25%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="Ver Dispositivo Asociado" data-toggle="modal" data-target="#dispositivoAsociadoModal${row.id}">
                        <i class="now-ui-icons ui-2_settings-90"></i>
                    </button>
                    <div class="modal fade" id="dispositivoAsociadoModal${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title">Dispositivo Asociado a ${row.nombre} ${row.apellido}</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="table-responsive table-style">
                                        <table class="table">
                                            <thead>
                                                <th class="text-primary">
                                                    UUID
                                                </th>
                                                <th class="text-primary">
                                                    Plataforma
                                                </th>
                                                <th class="text-primary">
                                                    Versión
                                                </th>
                                                <th class="text-primary">
                                                    Modelo
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        52700997491fac7f
                                                    </td>
                                                    <td>
                                                        Android
                                                    </td>
                                                    <td>
                                                        4.4.2
                                                    </td>
                                                    <td>
                                                        SM-G800F
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-info btn-round" data-dismiss="modal">Agregar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Administración de Usuarios Roadshow`);
    }

    ngOnInit() {
        this.obtenerUsuariosRoadshow();
    }

    obtenerUsuariosRoadshow() {
        this.tabla.setData(this.configTable);
    }
}
