
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Gtp } from '../../../../constants/gtp';
import { Constant } from '../../../../constants/constant';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable()
export class CheckoutMovilUsuarioService {

    public baseUrl: string = '';

    constructor(public http: Http,
        public gtp: Gtp,
        public constant: Constant) {
        this.baseUrl = environment.urlApiCheckoutMovil;
    }

    getRolesUsuarioMovil(): Observable<any[]> {
        return this.http.get(`${this.baseUrl}usuario/movil/rol`, this.constant.options)
            .map(response => response.json());
    }
    deleteRolesUsuarioMovil(usuarioId: any): Observable<any> {
        return this.http.delete(`${this.baseUrl}usuario/movil/${usuarioId}`, this.constant.options)
            .map(response => response.json());
    }
    getChoferes(clienteId: any): Observable<any[]> {
        return this.http.get(`${this.baseUrl}cliente/${clienteId}/chofer`, this.constant.options)
            .map(response => response.json());
    }
    guardarUsuario(body: any): Observable<any> {
        let passwordMd5 = Md5.hashStr(body.password);
        body.password = passwordMd5;
        return this.http.post(`${this.baseUrl}usuario/movil/new`, body, this.constant.options)
            .map(response => response.json());
    }
}