import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';
import { AlertService } from '../../../../comunes/alerta/services';
import { Constant } from '../../../../constants/constant';
import { CheckoutMovilDocumentoService } from '../services';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-checkout-movil-documento',
    templateUrl: '../templates/checkoutMovilDocumento.component.html'
})
export class CheckoutMovilDocumentoComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;
    public form: FormGroup;
    model: any = {};
    codCliente: any;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Documentos para calificar un Vehículo en Aplicación Checkout Movil `,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `vehiculo/documento`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'nombre',
                data: 'nombre',
                title: "Nombre",
                width: "20%",
                className: "dt-center"
            },
            {
                name: 'documento_calificacion',
                title: "Calificación",
                width: "20%",
                render: function (data: any, type: any, row: any, meta) {
                    let html;
                    html = `<span>Aprobado o Rechazado</span>`;
                    return html;
                }
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "30%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="Eliminar" data-toggle="modal" data-target="#eliminarUsuario${row.id}">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </button>
                    <div class="modal fade modal-mini modal-primary" id="eliminarUsuario${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center p-15">
                                    <div class="modal-profile">
                                        <i class="fas fa-question"></i>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <p>¿Está seguro que desea eliminar el registro?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-link btn-neutral" data-dismiss="modal">Cancelar</button>
                                    <button type="button" class="btn btn-link btn-neutral" (click)="eliminarUsuarioMovil(${row.id})">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false,
        filtro: {}
    }
    constructor(public gtp: Gtp,
        public constant: Constant,
        public checkoutMovilDocumentoService: CheckoutMovilDocumentoService,
        public formBuilder: FormBuilder,
        public alertService: AlertService) {
        this.gtp.setTituloCabecera(`Documentos de Checkout Movil`);
        this.codCliente = this.gtp.obtenerUsuarioActual().cod_cliente;
        this.configTable.filtro.cliente_codigo = this.codCliente;
    }

    ngOnInit() {
        //validaciones para el forumulario de usuario.
        let nombre = new FormControl('', Validators.required);

        this.form = this.formBuilder.group({
            nombre: nombre
        });
        // se obtiene la lista de usuarios móviles de la app de checkout de móviles.
        this.obtenerCheckoutMovilDocumento(1);
    }


    obtenerCheckoutMovilDocumento(pagina) {
        this.configTable.filtro.pagina = pagina;
        this.tabla.setData(this.configTable);
    }
    guardarVehiculoDocumento() {
        this.model.cliente_codigo = this.codCliente;
        // se guarda el usuario en la base de datos.
        this.checkoutMovilDocumentoService.guardarVehiculoDocumento(this.model)
            .subscribe(
                data => {
                    this.model = {};
                    this.alertService.success(this.constant.MSG_SUCCESS, this.constant.TITLE_SUCCESS);
                    this.obtenerCheckoutMovilDocumento(1);
                },
                error => {
                    this.alertService.error(JSON.stringify(error));
                });
    }
}
