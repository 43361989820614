import { Injectable } from '@angular/core';
import { Http } from "@angular/http";
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Title } from '@angular/platform-browser';

import { environment } from '../../environments/environment';

@Injectable()
export class RastreoService {

	//Listado de items de los diferentes menu
	public baseUrl: any = environment.baseUrl;
	public localUrl: any = environment.baseUrl_local;
	//private smoviles = new BehaviorSubject(null);
	//moviles = this.smoviles.asObservable();
	//Header Height
	public hHeight: any;
	//Idioma DateTimePicker
	fechalang: any = {
		firstDayOfWeek: 0,
		dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
		dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Juev", "Vie", "Sab"],
		dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
		monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
		today: 'Ahora',
		clear: 'Limpiar'
	};
	//authUser = new Subject<any>();
	constructor(
		private http: Http,
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) { }

	//Cambia el titurlo de la página
	setTitle(title: any = '') {
		let headTitle = (title != '') ? title + ' : ' + environment.title : title + environment.title;
		this.titleService.setTitle(headTitle);
	}
	//Parseo de Fecha Ej: 20/02/2018 13:16:34
	formatDate(date) {
		let now = new Date(date);
		let dia = (now.getDate() < 10) ? "0" + now.getDate() : now.getDate();
		let mes = ((now.getMonth() + 1) < 10) ? "0" + (now.getMonth() + 1) : (now.getMonth() + 1);
		let ano = now.getFullYear();
		let segundos = ((now.getSeconds() + 1) < 10) ? "0" + now.getSeconds() : now.getSeconds();
		let hora = now.getHours() + ':' + now.getMinutes() + ':' + segundos;
		let jsDate = dia + '/' + mes + '/' + ano + ' ' + hora;
		return jsDate.toString();
	}
	//Día actual desde la hora 00 hasta las 23
	DiaEntero() {
		let nowFdesde = new Date();
		nowFdesde.setHours(0, 0, 0, 0);
		let nowFhasta = new Date();
		nowFhasta.setHours(23, 59, 59);
		let fecha = {
			desde: nowFdesde,
			hasta: nowFhasta
		};
		return fecha;
	}
	//Trae el día y la hora actual
	DiaHoraActual() {
		let fecha = new Date();
		return fecha;
	}
	//Trae listado de items de la Botonera Top
	fetchMenuTop() {
		return this.http.get(`${this.localUrl}/rastreo-menu.php`)
			.map(res => res.json())
	}

	/**
	 * Función para obtener la fecha actual en cadena de texto Ej.: Viernes 10 de Agosto del 2018
	 */
    obtenerFechaActual() {
		let fecha = new Date();
		let d = this.fechalang.dayNames[fecha.getDay()]
		let m = this.fechalang.monthNames[fecha.getMonth()]
		let f = `${d} ${fecha.getDate()} de ${m} del ${fecha.getFullYear()}`
		console.log(f);
		return f;
	}
	
	/**
	 * Función para mostrar la hora en tiempo real.
	 * @param id identificador del elemento donde mostrar la hora actual
	 */
	obtenerHoraEnTiempoReal(id: string) {
		let momentoActual = new Date();
        let hora = momentoActual.getHours();
        let minuto = momentoActual.getMinutes(); 
        let segundo = momentoActual.getSeconds();

		let horaImprimible = hora + " : " + minuto + " : " + segundo;
		if (document.getElementById(id)) {
			document.getElementById(id).innerHTML = horaImprimible;
		}
	}

	/**
	 * Función para obtener el tiempo transcurrido entre una fecha pasada como parámetro y la fecha actaul, en minutos.
	 * @param date la última fecha para hacer el cálculo con la fecha actual y ver la diferencia de tiempo en minutos
	 */
	ObtenerTiempo(date: Date) {
		let fechaActual = this.DiaHoraActual()
		let ultimaFecha = new Date(date);
		let tiempo = fechaActual.getTime() - ultimaFecha.getTime();
		return tiempo/60000;
	}

	obtenerFechaFormateada (fecha: Date) {
		
		let dia = (fecha.getDate() < 10) ? "0" + fecha.getDate() : fecha.getDate();
		let mes = ((fecha.getMonth() + 1) < 10) ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1);
		let anho = fecha.getFullYear();
		return `${anho}-${mes}-${dia}`;
	}
}
