//angular
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
//services
import { AlertService } from '../services';

@Component({
    moduleId: module.id,
    selector: 'alerts',
    templateUrl: '../templates/alert.component.html'
})

export class AlertComponent implements OnDestroy {
    public subscription: Subscription;
    message: any;

    constructor(public alertService: AlertService) { 
        // subscribe to alert messages
        this.subscription = alertService.getMessage().subscribe(message => { this.message = message; });
    }

    ngOnDestroy(): void {
        // unsubscribe on destroy to prevent memory leaks
        this.subscription.unsubscribe();
    }
}