//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GMapModule, CalendarModule } from 'primeng/primeng';
import { choferesRouting } from './choferes.routing';
import { TablaModule } from '../../../shared/infotabla/infoTabla.module';
import { ChoferesComponent, ChoferPersonalizarComponent } from './components';
import { InlineSVGModule } from 'ng-inline-svg';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        choferesRouting,
        GMapModule,
        CalendarModule,
        TablaModule,
        InlineSVGModule,
        AccordionModule.forRoot(), 
        SharedModule
    ],
    declarations: [
        ChoferesComponent,
        ChoferPersonalizarComponent
    ],
    providers: [
        
    ],
    exports: [
        ChoferesComponent,
        ChoferPersonalizarComponent
    ]
})

export class ChoferesModule { }