import { Component, OnInit } from '@angular/core';

//Servicios
import { RastreoService } from '../../../services/rastreo.service';
import { SidebarService } from '../../services/sidebar.service';

/*

s-moviles: Sidebar Moviles

*/

declare const $;

@Component({
  selector: 's-moviles',
  templateUrl: './s-moviles.component.html',
  styleUrls: ['./s-moviles.component.scss']
})
export class SMovilesComponent implements OnInit {
  oneAtATime: boolean = true;
  //Temp para filtrados
  temp = [];  
  //Listado de Moviles
  moviles:any;
  //Listado de items de Moviles
  moviles_menu:any;
  //Tabs
  tab_todos:any = true;
  tab_activos:any = false;

  constructor(
    private _rastreo:RastreoService,
    private _sidebar:SidebarService
  ){ }

  ngOnInit() {    
    this.getMenuMoviles();
    this.getMoviles();       
    this._sidebar.setSideMoviles();
    $('.collapse').collapse('hide');
  }

  //Carga los items del Menú de los Móviles del Sidebar
  getMenuMoviles(){
    this._sidebar.fetchMenuMoviles().subscribe(
      res=> {
        this.moviles_menu = res;
      },
      //Error
      err => { 
        //console.log(err);
      },
      //Terminó de cargar
      () => {  }
    );    
  }
  
  //Carga los Móviles del sidebar
  getMoviles(){
    this._sidebar.moviles.subscribe(res => {
      if(res!=null){
        this.moviles = res;
        this.temp = res;
      }
    });
  }

  //Filtro de Busqueda de Móvil
  filtrarMovil(event){
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {            
      var res;
      //Filtra por nombre
      var nombre = d.des_equipo_ratreo.toLowerCase().toString().indexOf(val) !== -1 || !val; if (nombre) { res = nombre; }
      //Filtra por Cod Veh
      var cod_vehiculo = d.cod_vehiculo.toString().indexOf(val) !== -1 || !val; if (cod_vehiculo) { res = cod_vehiculo; }
      //Filtra por Fecha
      var fecha = d.ult_fecha_hora.toLowerCase().toString().indexOf(val) !== -1 || !val; if (fecha) { res = fecha; }
      return res;
    });
    this.moviles = temp;
  }  

  //Filtro de los Tab ( Todos / Activos )
  filtrarTab(obj){
    let temp;
    const val = true;  
    this.tab_todos = (obj) ? false : true;
    this.tab_activos = (obj) ? true : false;
    temp = this.temp.filter(function (d) {            
        let res;
        let mov = (obj) ? d.movimiento : (d.nombre);
        if (mov) { res = mov; }
        return res;
    });
    this.moviles = temp;
  }

}
