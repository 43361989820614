import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
    public image:any;
    @Input('icon') icon: string;
    @Input('nameImage') nameImage: string;
    @Output() returnImage = new EventEmitter();
  
    fileChange(input){
        const reader = new FileReader();
        if (input.files.length) {
            const file = input.files[0];
            reader.onload = () => {
                this.image = reader.result;
                this.returnImage.emit({nombre: this.nameImage, image: this.image, file: file});
            }
            reader.readAsDataURL(file);           
        }
    }

    removeImage():void{
        this.image = '';
    }
}

