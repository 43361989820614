import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';
import { RastreoService } from '../../../../services/rastreo.service';


@Component({
    selector: 'app-grupos',
    templateUrl: '../templates/grupos.component.html'
})
export class GruposComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;
    //Datos de Lenguaje del DateTime Picker
    fechalang: any;
    maxFechaDesde = new Date();
    maxFechaHasta = new Date();
    fechaHoraDesde;
    fechaHoraHasta;
    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Grupos`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `grupos`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'codigo',
                data: 'codigo',
                title: "Codigo",
                width: "40%",
                className: "dt-center"
            },
            {
                name: 'descripcion',
                data: 'descripcion',
                title: "Descripción",
                width: "40%"
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "20%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-warning btn-icon btn-sm " data-original-title="Asignar" data-toggle="modal" data-target="#asignar${row.id}">
                        <i class="now-ui-icons ui-1_simple-add"></i>
                    </button>
                    <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="Editar" data-toggle="modal" data-target="#editarGrupo${row.id}">
                        <i class="now-ui-icons ui-2_settings-90"></i>
                    </button>
                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="Eliminar" data-toggle="modal" data-target="#eliminarGrupo${row.id}">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </button>
                    <div class="modal fade modal-mini modal-primary" id="eliminarGrupo${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center p-15">
                                    <div class="modal-profile">
                                        <i class="fas fa-question"></i>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <p>¿Está seguro que desea eliminar el registro?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-link btn-neutral" data-dismiss="modal">Cancelar</button>
                                    <button type="button" class="btn btn-link btn-neutral">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="asignar${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title">Asignar Usuarios y Vehículos al grupo ${row.descripcion}</h4>
                                </div>
                                <div class="modal-body">
                                    <h1 class="title-list">Lista de Usuarios</h1>
                                    <div class="table-responsive table-style">
                                        <table class="table">
                                            <thead>
                                                <th class="text-primary">
                                                    Seleccionar
                                                </th>
                                                <th class="text-primary">
                                                    Usuario
                                                </th>
                                                <th class="text-primary">
                                                    Administrador Grupal
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        global
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        admin
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>                                          
                                            </tbody>
                                        </table>
                                    </div>  
                                    <h1 class="title-list">Lista de Vehículos</h1>
                                    <div class="table-responsive table-style">
                                        <table class="table">
                                            <thead>
                                                <th class="text-primary">
                                                    Seleccionar
                                                </th>
                                                <th class="text-primary">
                                                    Descripción
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        173 - NISSAN BGD-620 GTP SRL
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        3372 - KENTON, GL 125, NEGRO, GTP ENC, CHASIS (1614)
                                                    </td>
                                                </tr>                                          
                                            </tbody>
                                        </table>
                                    </div>  
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-info btn-round" data-dismiss="modal">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="editarGrupo${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title">Editar Grupo</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="row sepform">
                                        <div class="col">
                                            <label>
                                                Nombre
                                            </label>
                                            <input class="form-control bl-1" name="nombre" type="text" required="true" />
                                        </div>
                                    </div>
                                    <div class="row sepform">
                                        <div class="col">
                                            <label>
                                                Fecha Desde
                                            </label>
                                            <p-calendar [locale]="fechalang" name="fdesde" [(ngModel)]="fechaHoraDesde" class="form-control p-0 inputfecha" dateFormat="yy/mm/dd"
                                                showTime="true" showSeconds="true" hourFormat="24" showButtonBar="true"
                                                [maxDate]="maxFechaDesde"></p-calendar>
                                        </div>
                                        <div class="col">
                                            <label>
                                                Fecha Hasta
                                            </label>
                                            <p-calendar [locale]="fechalang" name="fhasta" [(ngModel)]="fechaHoraHasta" class="form-control p-0 inputfecha" dateFormat="yy/mm/dd"
                                                showTime="true" showSeconds="true" hourFormat="24" showButtonBar="true"
                                                [minDate]="fechaHoraDesde" [maxDate]="maxFechaHasta"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row sepform">
                                        <div class="col pos-left-0">
                                            <label>
                                                Días de la Semana
                                            </label>
                                            <select class="selectpicker" data-style="btn btn-info btn-round" multiple title="Seleccione las opciones" data-size="7">
                                                <option disabled> Seleccione las opciones</option>
                                                <option value="1">Domingo </option>
                                                <option value="2">Lunes</option>
                                                <option value="3">Martes</option>
                                                <option value="4">Miércoles</option>
                                                <option value="5">Jueves</option>
                                                <option value="6">Viernes</option>
                                                <option value="7">Sábado</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-info btn-round" data-dismiss="modal">Agregar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp,
        public _rastreo: RastreoService, ) {
        //Trae la fecha inicial del día
        this.obtenerFechaInicial();
        //Trae la fecha con idioma español
        this.fechalang = this._rastreo.fechalang;
        this.gtp.setTituloCabecera(`Administración de Grupos`);
    }

    ngOnInit() {
        this.obtenerGrupos();
    }


    obtenerGrupos() {
        this.tabla.setData(this.configTable);
    }
    //Función para obtener la Fecha del día para utilizar en el filtro.
    obtenerFechaInicial() {
        this.fechaHoraDesde = this._rastreo.DiaHoraActual();
        this.fechaHoraDesde.setHours(0, 0, 0, 0);
        this.fechaHoraHasta = this._rastreo.DiaHoraActual();
    }
}
