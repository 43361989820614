//angular
import { Routes, RouterModule } from '@angular/router';
import { ChoferesComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const choferesRoutes: Routes = [
    {
        path: 'administracion/choferes',
        children: [
            {
                path: 'lista',
                component: ChoferesComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const choferesRouting = RouterModule.forChild(choferesRoutes);