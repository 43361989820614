import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

//Servicios
import { SidepanelsService } from './rastreo/services/sidepanels.service';
import { RastreoService } from './services/rastreo.service';
import { Subscription } from 'rxjs';
import { SidebarService } from './rastreo/services/sidebar.service';
import { AuthenticationService } from './rastreo/login/services';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent { 
  
  mainClass:any;
  subscription: Subscription;
  showSite: boolean = true;

  constructor(
    public authService:AuthenticationService,
    private _rastreo:RastreoService,
    private _panel:SidepanelsService
  ){        
    this.setMainClass();
  }

  setMainClass(){
    let panel = this._panel.getPanelInfo();
    this.subscription = this._panel.getMainClass().subscribe(resp => { this.mainClass = resp; });
    this._panel.setPanelSizes();
  }

  ngOnInit(){
    
  }
  
  //Setea el titulo de la interna
  setTitle(outlet){
    console.log(outlet)
    let titulo = outlet.activatedRoute.data._value.title;
    this._rastreo.setTitle(titulo);
  }

  setData(outlet){
    //this.setTitle(outlet);
  }

  showOrHideshowSite() {
    this.showSite = !this.showSite;
  }
 
}
