import { Component, OnInit } from '@angular/core';
import { RastreoService } from '../../../../services/rastreo.service';
import { Gtp } from '../../../../constants/gtp';
import { AlertService } from '../../../../comunes/alerta/services';
import { InicioService } from '../../../inicio/services';

declare const $;

@Component({
    selector: 'app-choferes',
    templateUrl: '../templates/choferes.component.html'
})

export class ChoferesComponent implements OnInit {

    //Temporal de filtrado
    temp: any[];
    //Listado de Choferes
    lista: any[];
    sideLeftHeight: any;
    sideRightHeight: any;
    codCliente: string;
    codChofer: string;

    constructor(
        public _rastreo: RastreoService,
        public alertService: AlertService,
        public inicioService: InicioService,
        public gtp: Gtp) {
        this.gtp.setTituloCabecera('Administración de Choferes')
    }

    ngOnInit() {
        this.codCliente = this.gtp.obtenerUsuarioActual().cod_cliente;
        this.mainBars();
        this.getMoviles();
        let that = this;
        window.onresize = function () { that.mainBars(); };
    }

    ngAfterViewInit() {
    }

    /**
     * Función para obtener la lista de choferes de un cliente.
     */
    getMoviles() {
        this.lista = [
            {id_chofer: 1, nombre_chofer: 'Ruebén Toledo', tel_chofer: '0981234567', cod_vehiculo: 10008, matricula: 'GFFT00', cod_cliente: 127},
            {id_chofer: 2, nombre_chofer: 'José Perez', tel_chofer: '0981234567', cod_vehiculo: 11008, matricula: 'GFFT00', cod_cliente: 127},
            {id_chofer: 3, nombre_chofer: 'Sonia Laviosa', tel_chofer: '0981234567', cod_vehiculo: 20008, matricula: 'GFFT00', cod_cliente: 127},
            {id_chofer: 4, nombre_chofer: 'Rodrigo Santos', tel_chofer: '0981234567', cod_vehiculo: 104408, matricula: 'GFFT00', cod_cliente: 127},
            {id_chofer: 5, nombre_chofer: 'Sergio Ballesteros', tel_chofer: '0981234567', cod_vehiculo: 44008, matricula: 'GFFT00', cod_cliente: 127},
            {id_chofer: 6, nombre_chofer: 'Carlos Vallejos', tel_chofer: '0981234567', cod_vehiculo: 2330, matricula: 'GFFT00', cod_cliente: 127},
        ]
        // this.inicioService.obtenerTodosLoschoferes(this.codCliente)
        //   .subscribe(
        //     data => {
        //       this.moviles = data;
        //       this.temp = [...data];
        //       this.personalizarChofer(this.moviles[0].cod_equipo_rastreo);
        //     },
        //     error => {
        //       this.alertService.error(JSON.stringify(error));
        //     });
        this.personalizarChofer(this.lista[0].id_chofer);
    }

    mainBars() {
        //Traigo el Height del Header
        let hHeight = this._rastreo.hHeight;
        //Traigo el Height del titulo + borde
        let mHeaderHeight = this._rastreo.hHeight;
        //Modifico la Altura del SideLeft
        this.sideLeftHeight = window.innerHeight - (hHeight + mHeaderHeight);
        this.sideRightHeight = window.innerHeight - (hHeight + mHeaderHeight);
    }
    /**
     * Función para filtrar la lista de vehículos por descripción, código o fecha.
     * @param event evente del caracter ingresado para la búsqueda.
     */
    filtrarLista(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            var res;
            //Filtra por nombre
            var nombre = d.des_equipo_ratreo.toLowerCase().toString().indexOf(val) !== -1 || !val; if (nombre) { res = nombre; }
            //Filtra por Cod Veh
            var cod_choferes = d.cod_equipo_rastreo.toString().indexOf(val) !== -1 || !val; if (cod_choferes) { res = cod_choferes; }
            //Filtra por Fecha
            var fecha = d.ult_fecha_hora.toLowerCase().toString().indexOf(val) !== -1 || !val; if (fecha) { res = fecha; }
            return res;
        });
        this.lista = temp;
    }

    personalizarChofer(id) {
        this.codChofer = id;
    }
}
