//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablaModule } from '../../../shared/infotabla/infoTabla.module';
import { ImportarPuntosComponent, ImportarPuntosGPXComponent, ImportarPuntosXLSRoadshowComponent, UbicarCallesComponent } from './components';
import { puntosRouting } from './puntos.routing';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TablaModule,
        puntosRouting,
        SharedModule
    ],
    declarations: [
        ImportarPuntosComponent,
        ImportarPuntosGPXComponent,
        ImportarPuntosXLSRoadshowComponent,
        UbicarCallesComponent
    ],
    providers: [

    ],
    exports: [
        ImportarPuntosComponent,
        ImportarPuntosGPXComponent,
        ImportarPuntosXLSRoadshowComponent,
        UbicarCallesComponent
    ]
})

export class PuntosModule { }