//angular
import { Routes, RouterModule } from '@angular/router';
import { AccesoSistemaComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const accesoSistemaRoutes: Routes = [
    {
        path: 'administracion/acceso/sistema',
        children: [
            {
                path: 'lista',
                component: AccesoSistemaComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const accesoSistemaRouting = RouterModule.forChild(accesoSistemaRoutes);