import { Component, OnInit } from '@angular/core';

import { GMapModule } from 'primeng/primeng';

declare var google: any;

@Component({
  selector: 'app-m-flota',
  templateUrl: './m-flota.component.html',
  styleUrls: ['./m-flota.component.scss']
})
export class MFlotaComponent implements OnInit {

  //Tamaño Header
  headerHeight:any = 86;
  //Tamaño Mapa
  mapHeight:any;

  latitud:string = '-25.269872';
  longitud:string = '-57.593346';  
  mapOptions:any = {
    center: null,
    zoom: 12
  }; 
  overlays:any;
  //gmap:any;
  
  constructor(
  ){
    this.mapHeight = (window.screen.height)-this.headerHeight;
  }

  ngOnInit() {

    this.mapOptions.center = {
      lat: parseInt(this.latitud),
      lng: parseInt(this.longitud)
    };

    var icon = "http://www.google.com/mapfiles/markerA.png";
    
    this.overlays = [
      
      new google.maps.Marker({
         position: {
           lat: parseInt(this.latitud), 
           lng: parseInt(this.longitud)
         },
         title:"Posición",
         icon: icon
      })

    ];    

  }

  

}