import { Component, SecurityContext, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alertmsg',
  templateUrl: './alertmsg.component.html',
  styleUrls: ['./alertmsg.component.scss']
})
export class AlertmsgComponent implements OnInit {

  dismissible = true;
  alerts: any = [];
  alerts2: any = [
    {
      type: 'success',
      msg: `<h4 class="alert-heading">Well done!</h4>
      <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p>
      <p class="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>`
    },
    {
      type: 'info',
      msg: `<strong>Heads up!</strong> This alert needs your attention, but it's not super important.`
    },
    {
      type: 'danger',
      msg: `<strong>Warning!</strong> Better check yourself, you're not looking too good.`
    },
    {
      type: 'success',
      msg: `<strong>Well done!</strong> You successfully read this important alert message.`
    }
  ];

  constructor(private sanitizer: DomSanitizer) {
      this.getMensajes();
  }

  ngOnInit(){}

  getMensajes(){
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: this.sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  reset(): void {
    this.alerts = this.alerts.map((alert: any) => Object.assign({}, alert));
  }

  onClosed($event, id){
    console.log(id);
    console.log($event);
  }

}
