import { Component, OnInit, ViewChild } from '@angular/core';
import { RastreoService } from '../../../../services/rastreo.service';
import { RecorridoService } from '../services';
import { AlertService } from '../../../../comunes/alerta/services';
import { GooglemapComponent } from '../../../../shared/googlemap/googlemap.component';
import { Gtp } from '../../../../constants/gtp';
import { ActivatedRoute, Router } from '@angular/router';

declare const $;

@Component({
    selector: 'app-recorrido',
    templateUrl: '../templates/recorrido.component.html'
})

export class RecorridoComponent implements OnInit {

    @ViewChild(GooglemapComponent) googleMap: GooglemapComponent;
    //Datos de Lenguaje del DateTime Picker
    fechalang: any;
    maxFechaDesde = new Date();
    maxFechaHasta = new Date();
    fechaHoraDesde;
    fechaHoraHasta;
    //Valores Fecha Desde / Hasta y limite de fila
    filtro: any = {
        fdesde: Date,
        fhasta: Date,
        limitrows: 100
    };
    codVehiculo: any;
    vehiculo: any;


    constructor(
        public _rastreo: RastreoService,
        public alertService: AlertService,
        public route: ActivatedRoute,
        public router: Router,
        public gtp: Gtp,
        public recorridoService: RecorridoService) {
        //Trae la fecha inicial del día
        this.obtenerFechaInicial();
        //Trae la fecha con idioma español
        this.fechalang = this._rastreo.fechalang;
        this.vehiculo = JSON.parse(gtp.getItemLocalStorage('vehiculo'));
        //setea el título de la cabecera principal
        this.gtp.setTituloCabecera(`Recorrido - Móvil - ${this.vehiculo.des_equipo_ratreo}`);
    }

    ngOnInit() {
        //se obtiene el código del vehículo
        this.codVehiculo = this.route.snapshot.paramMap.get('id');
    }

    mostrarTodasLasPosicionesPorVehiculo() {
        this.filtro.fdesde = this._rastreo.obtenerFechaFormateada(this.fechaHoraDesde);
        this.filtro.fhasta = this._rastreo.obtenerFechaFormateada(this.fechaHoraHasta)
        this.recorridoService.obtenerTodasLasPosicionesPorVehiculo(this.filtro, this.codVehiculo)
            .subscribe(
                data => {
                    this.googleMap.setMap(data);
                },
                error => {
                    this.alertService.error(JSON.stringify(error));
                },
                //Terminó de cargar
                () => { }
            );
    }
    //Función para obtener la Fecha del día para utilizar en el filtro.
    obtenerFechaInicial() {
        this.fechaHoraDesde = this._rastreo.DiaHoraActual();
        this.fechaHoraDesde.setHours(0, 0, 0, 0);
        this.fechaHoraHasta = this._rastreo.DiaHoraActual();
    }

    getTiempo(input, $event) {
        let fecha = this._rastreo.DiaHoraActual();
        if (input == 'fdesde') { this.filtro.fdesde = fecha; }
        if (input == 'fhasta') { this.filtro.fhasta = fecha; }
    }

}
