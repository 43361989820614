import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Servicios
import { AuthenticationService } from '../services';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../../comunes/alerta/services';

@Component({
  selector: 'app-login',
  templateUrl: '../templates/login.component.html',
  styleUrls: ['../templates/login.component.scss']
})
export class LoginComponent implements OnInit {

  model: any = {};
  loading = false;
  error = '';
  public form: FormGroup;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public authenticationService: AuthenticationService,
    public formBuilder: FormBuilder,
    public alertService: AlertService
  ) {

  }

  ngOnInit() {
    this.authenticationService.logout();
    //validaciones para el forumulario de login.
    let username = new FormControl('', Validators.required);
    let password = new FormControl('', Validators.required);

    this.form = this.formBuilder.group({
      username: username,
      password: password,
    });
  }

  /**
   * Función para inicio de sesión del usuario, dado su usuario y contraseña
   */
  login() {
    this.loading = true;
    //llama al service para iniciar sesion. 
    this.authenticationService.login(this.model)
      .subscribe(
        //caso exitoso.
        data => {
          this.router.navigate(['/inicio']);
          this.loading = false;
        },
        //caso de error.
        error => {
          //llama al service que realiza el manejo de error.
          this.alertService.error(JSON.stringify(error));
          this.loading = false;
        }
      );
  }

}
