import { Component, OnInit, ViewChild } from '@angular/core';
import { Gtp } from '../../../../constants/gtp';
import { AlertService } from '../../../../comunes/alerta/services';
import { RastreoService } from '../../../../services/rastreo.service';
import { InicioService } from '../../../inicio/services';

@Component({
    selector: 'app-checkout-movil-item',
    templateUrl: '../templates/checkoutMovilHistorial.component.html'
})
export class CheckoutMovilHistorialComponent implements OnInit {

    //Temporal de filtrado
    temp: any;
    //Listado de Móviles
    moviles: any;
    sideLeftHeight: any;
    sideRightHeight: any;
    codCliente: string;
    codVehiculo: string;

    constructor(
        public _rastreo: RastreoService,
        public alertService: AlertService,
        public inicioService: InicioService,
        public gtp: Gtp) {
        this.gtp.setTituloCabecera('Historial de Checkout Móviles')
    }

    ngOnInit() {
        this.codCliente = this.gtp.obtenerUsuarioActual().cod_cliente;
        this.mainBars();
        this.getMoviles();
        let that = this;
        window.onresize = function () { that.mainBars(); };
    }

    ngAfterViewInit() {
    }

    /**
     * Función para obtener la lista de vehículos de un cliente.
     */
    getMoviles() {
        this.inicioService.obtenerTodosLosVehiculos(this.codCliente)
            .subscribe(
                data => {
                    this.moviles = data;
                    this.temp = [...data];
                    this.personalizarVehiculo(this.moviles[0].cod_equipo_rastreo);
                },
                error => {
                    this.alertService.error(JSON.stringify(error));
                });
    }

    mainBars() {
        //Traigo el Height del Header
        let hHeight = this._rastreo.hHeight;
        //Traigo el Height del titulo + borde
        let mHeaderHeight = this._rastreo.hHeight;
        //Modifico la Altura del SideLeft
        this.sideLeftHeight = window.innerHeight - (hHeight + mHeaderHeight);
        this.sideRightHeight = window.innerHeight - (hHeight + mHeaderHeight);
    }
    /**
     * Función para filtrar la lista de vehículos por descripción, código o fecha.
     * @param event evente del caracter ingresado para la búsqueda.
     */
    filtrarMovil(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            var res;
            //Filtra por nombre
            var nombre = d.des_equipo_ratreo.toLowerCase().toString().indexOf(val) !== -1 || !val; if (nombre) { res = nombre; }
            //Filtra por Cod Veh
            var cod_vehiculo = d.cod_equipo_rastreo.toString().indexOf(val) !== -1 || !val; if (cod_vehiculo) { res = cod_vehiculo; }
            //Filtra por Fecha
            var fecha = d.ult_fecha_hora.toLowerCase().toString().indexOf(val) !== -1 || !val; if (fecha) { res = fecha; }
            return res;
        });
        this.moviles = temp;
    }

    personalizarVehiculo(id) {
        this.gtp.setItemLocalStorage('vehiculoId', id);
        this.codVehiculo = id;
    }
}
