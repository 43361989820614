import { Component, OnInit, ViewChild } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

//Mapa Google Pop
import { Subscription } from 'rxjs';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { MUbicacionComponent } from '../../../../mapa/m-ubicacion/m-ubicacion.component';
import { environment } from '../../../../../environments/environment';
import { RastreoService } from '../../../../services/rastreo.service';
import { Gtp } from '../../../../constants/gtp';

declare const $;

@Component({
  selector: 'app-control-velocidades',
  templateUrl: '../templates/control-velocidades.component.html',
  styleUrls: ['../templates/control-velocidades.component.scss']
})

export class ControlVelocidadesComponent implements OnInit {

  @ViewChild(TablaComponent) tabla: TablaComponent;
  @ViewChild(MUbicacionComponent) mapaPop: MUbicacionComponent;

  mainClass: any;
  subscription: Subscription;
  //Datos de Lenguaje del DateTime Picker
  fechalang: any;
  // máxima fecha para la fecha desde
  maxFechaDesde = new Date();
  // máxima fecha para la fecha hasta
  maxFechaHasta = new Date();
  fechaHoraDesde;
  fechaHoraHasta;
  velocidad;
  //Valores Fecha Desde / Hasta
  filtro: any = {
    fdesde: Date,
    fhasta: Date,
    velocidad: environment.velLimit //Velocidad default
  };

  modalRef: BsModalRef;
  tableData: any;
  configTable: any = {
    //Nombre del archivo a Exportar
    filename: "Control de velocidad de la flota",
    //Usuario Genero PDF
    user_g: "",
    //Totales top
    headerPdf: ``,
    //Link endPoint
    endpoint: `control-velocidad`,
    //Configuración de columnas    
    t_columns: [
      {
        name: 'id-nombre',
        data: 'nombre',
        title: "Nombre",
        width: "14%"
      },
      {
        name: 'fecha',
        data: 'fecha',
        title: "Fecha",
        width: "8%",
        className: "dt-center"
      },
      {
        name: 'direccion',
        data: 'direccion',
        title: "Dirección",
        width: "30%"
      },
      {
        name: 'velocidad',
        data: 'velocidad',
        title: "Velocidad",
        width: "4%",
        className: "dt-center"
      },
      {
        name: 'veren',
        orderable: false,
        title: "Ver",
        width: "4%",
        render: function (data: any, type: any, row: any, meta) {
          console.log(row)
          localStorage.setItem(row.id, JSON.stringify(row));
          let divClass = 'btn buttons-html5 color-dark-blue btn-accion btn-round';
          var html = `
          <button class='${divClass}' data-showmodal="${row.latitud};${row.longitud};${row.id}" title="Ubicación">
            <i class="fas fa-map-marker-alt"></i> 
            <span class="label-action">GoogleMap</span>
          </button>`;
          return html;
        }
      }
    ],
    // Columnas a exportar
    exp_columns: [0, 1, 2, 3],
    // Columa que debe llevar al header
    htarget: 0,
    // Columna con botones de acciones
    actionbtn: 4,
    // Agrupa o no (Deja un header arriba)  
    agrupar: false,
  }

  constructor(
    private _rastreo: RastreoService,
    private modalService: BsModalService,
    public gtp: Gtp) {
    //Trae la fecha inicial del día
    this.obtenerFechaInicial();
    //Trae la fecha con idioma español
    this.fechalang = this._rastreo.fechalang;
    //setea el título de la cabecera principal
    this.gtp.setTituloCabecera('Control de Velocidad');

  }

  ngOnInit() {
    this.obtenerControlVelocidadPorFiltro();
  }

  /**
   * Función para obtener la Fecha del día para utilizar en el filtro.
   */
  obtenerFechaInicial() {
    this.fechaHoraDesde = this._rastreo.DiaHoraActual();
    this.fechaHoraDesde.setHours(0, 0, 0, 0);
    this.fechaHoraHasta = this._rastreo.DiaHoraActual();
    this.velocidad = environment.velLimit;
  }
  /**
   * Funcion para setear los valores de los filtros en la tabla para poderlos filtrar por eso datos.
   */
  setFiltros() {
    this.tabla.filtros = {
      fdesde: this.fechaHoraDesde,
      fhasta: this.fechaHoraHasta,
      velocidad: this.velocidad
    };
  }

  /**
   * Función para obtener la lista con filtro y paginado de la distancia recorrida de toda la flota
   */
  obtenerControlVelocidadPorFiltro() {
    //seteamos los datos del filtro
    this.setFiltros();
    // obtenemos los móviles
    this.tabla.setData(this.configTable);
  }
  /**
   * Función para mostrar mediante un modal la posición del vehículo en el mapa con todos los datos.
   * @param data datos de posiciones
   */
  showModal(data) {
    let map = data.split(";");
    let obj = JSON.parse(localStorage.getItem(map[2]));
    // se construye el punto con todos los datos y detalles a mostrar en el mapa mediante el modal
    let punto = [{
      latitud: obj.latitud,
      longitud: obj.longitud,
      velocidad: obj.velocidad,
      geoinversa: obj.direccion,
      cod_equipo_rastreo: obj.codvehiculo,
      fecha_hora: obj.fecha
    }];
    // configuración inicial de datos a enviar al componente Modal.
    const initialState = {
      title: obj.nombre,
      puntos: punto
    };
    // configuraciones propias del modal.
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false
    };
    // se instancia el modal a mostrar.
    this.modalRef = this.modalService.show(
      MUbicacionComponent,
      Object.assign({ initialState }, config, { class: 'gray modal-lg' })
    );
    this.modalRef.content.closeBtnName = 'Cerrar';
  }

}
