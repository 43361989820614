
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import { Gtp } from '../../../../constants/gtp';
import { Constant } from '../../../../constants/constant';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CheckoutMovilItemPuntuacionService {

    public baseUrl: string = '';

    constructor(public http: Http,
        public gtp: Gtp,
        public constant: Constant) {
        this.baseUrl = environment.urlApiCheckoutMovil;
    }

    getItemPuntuacionTipo(clienteId): Observable<any[]> {
        return this.http.get(`${this.baseUrl}cliente/${clienteId}/vehiculo/item/puntuacion/tipo`, this.constant.options)
            .map(response => response.json());
    }
}