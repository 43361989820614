import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { environment } from '../environments/environment';

//Auth
import { AuthGuard } from './auth/auth.guard';

// -- Secciones
import { InicioComponent } from './rastreo/inicio/components/inicio.component';

// Control de FLota
import { TiempoContactoonComponent } from './rastreo/controlflota/tiempo-contactoon/tiempo-contactoon.component';
import { PrimerMovimientoComponent } from './rastreo/controlflota/primer-movimiento/primer-movimiento.component';
import { CtrlgeocercasComponent } from './rastreo/controlflota/ctrlgeocercas/ctrlgeocercas.component';
import { DistanciamovilesComponent } from './rastreo/controlflota/distanciamoviles/distanciamoviles.component';
import { LoginComponent } from './rastreo/login/components';

export const routes: Routes = [
    { path: '', redirectTo: '/rastreo/login', pathMatch: 'full' }
    // { 
    //     path: 'administracion',
    //     children: [
    //         {          
    //             path: 'vehiculos',
    //             component: VehiculosComponent,
    //             data: {
    //                 title: 'Listado de Vehiculos'
    //             }
    //         }
    //     ],
    //     canActivate: [AuthGuard]
    // },
    // { 
    //     path: 'controlflota',
    //     children: [
    //         {          
    //             path: 'control-velocidades',
    //             component: ControlVelocidadesComponent,
    //             data: {
    //                 title: 'Control de Velocidades'
    //             }
    //         },
    //         {          
    //             path: 'distancia-recorrida',
    //             component: DistanciaRecorridaComponent,
    //             data: {
    //                 title: 'Distancia Recorrida por Móvil'
    //             }
    //         },
    //         {          
    //             path: 'tiempo-contacto-on',
    //             component: TiempoContactoonComponent,
    //             data: {
    //                 title: 'Tiempo de contacto ON por Móvil'
    //             }
    //         },
    //         {          
    //             path: 'primer-movimiento-dia',
    //             component: PrimerMovimientoComponent,
    //             data: {
    //                 title: 'Primer Movimiento del Día'
    //             }
    //         },
    //         {          
    //             path: 'control-geocercas',
    //             component: CtrlgeocercasComponent,
    //             data: {
    //                 title: 'Control de Geocercas'
    //             }
    //         },
    //         {          
    //             path: 'distancia-entre-moviles',
    //             component: DistanciamovilesComponent,
    //             data: {
    //                 title: 'Distancia entre moviles'
    //             }
    //         },
    //         {          
    //             path: 'ver-toda-flota',
    //             component: VerflotaComponent,
    //             data: {
    //                 title: 'Ver toda la flota'
    //             }
    //         }
    //     ],
    //     canActivate: [AuthGuard]
    // },
    // { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }