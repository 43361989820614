import { Component, OnInit, Input, Output, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Http, Response, RequestOptions } from "@angular/http";
import { environment } from '../../../environments/environment';

import { RastreoService } from '../../services/rastreo.service';
import { Constant } from '../../constants/constant';

declare const $;

@Component({
  selector: 'infoTabla',
  template: `
    <div class="col-12 contInfo not-padding">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 infomain mt-4 not-padding">
              <table
                cellspacing="0"
                id="tablaID"
                width="100%"
                class="display wrap col-12 p-0 table table-striped table-bordered hover">
              </table>
        </div>
    </div>
  `,
  styles: ['']
})

export class TablaComponent implements AfterViewInit {

  @Input() tableData: any = '';
  @Output() msgModal = new EventEmitter<any>();
  tableId: any;

  //Config Tabla
  public datatable: any;             // Instancia Datatable
  //Filtros Tabla
  public filtros: any;

  // TODO reemplezar cuando se tengan los endpoints correspondientes.
  response = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { "id": 1, "latitud": "-27.330790", "longitud": "-55.870350", "velocidad": "0.00", "fdesde": "2018-08-20 00:06:30", "fhasta": "2018-08-20 08:21:44", "calle": "VILLARRICA y Villarrica, ENCARNACION, ITAPUA <b>LOCALIDAD<\/b>: SAN BLAS<br\/><b>LOCALIDAD<\/b>: SAN BLAS<br\/><b>BARRIO<\/b>: SAN BLAS<br\/><b>DISTRITO<\/b>: ENCARNACION<br\/>", "punto_cercano": "a 33 mts, <b>GTP<\/b><br \/>a 104 mts, <b>SKYNET<\/b><br \/>a 173 mts, <b>GAMBLIN<\/b><br \/>", "recorrido_entre_puntos": "0,00 Km", "recorrido_acumulado": "0,00 km", "recorrido_acumulado_raw": 0, "duracion": "0 d&iacute;as 08:15:14" },
      { "id": 2, "latitud": "-27.330790", "longitud": "-55.870350", "velocidad": "0.00", "fdesde": "2018-08-20 00:06:30", "fhasta": "2018-08-20 08:21:44", "calle": "VILLARRICA y Villarrica, ENCARNACION, ITAPUA <b>LOCALIDAD<\/b>: SAN BLAS<br\/><b>LOCALIDAD<\/b>: SAN BLAS<br\/><b>BARRIO<\/b>: SAN BLAS<br\/><b>DISTRITO<\/b>: ENCARNACION<br\/>", "punto_cercano": "a 33 mts, <b>GTP<\/b><br \/>a 104 mts, <b>SKYNET<\/b><br \/>a 173 mts, <b>GAMBLIN<\/b><br \/>", "recorrido_entre_puntos": "0,00 Km", "recorrido_acumulado": "0,00 km", "recorrido_acumulado_raw": 0, "duracion": "0 d&iacute;as 08:15:14" },
      { "id": 3, "latitud": "-27.330790", "longitud": "-55.870350", "velocidad": "0.00", "fdesde": "2018-08-20 00:06:30", "fhasta": "2018-08-20 08:21:44", "calle": "VILLARRICA y Villarrica, ENCARNACION, ITAPUA <b>LOCALIDAD<\/b>: SAN BLAS<br\/><b>LOCALIDAD<\/b>: SAN BLAS<br\/><b>BARRIO<\/b>: SAN BLAS<br\/><b>DISTRITO<\/b>: ENCARNACION<br\/>", "punto_cercano": "a 33 mts, <b>GTP<\/b><br \/>a 104 mts, <b>SKYNET<\/b><br \/>a 173 mts, <b>GAMBLIN<\/b><br \/>", "recorrido_entre_puntos": "0,00 Km", "recorrido_acumulado": "0,00 km", "recorrido_acumulado_raw": 0, "duracion": "0 d&iacute;as 08:15:14" }
    ]
  }

  responseUsuarios = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, usuario: 'global', perfil: 'Administrador' },
      { id: 2, usuario: 'slaviosa', perfil: 'Operario' },
      { id: 3, usuario: 'rsantos', perfil: 'Limitado' }
    ]
  }
  responseCheckoutMovilUsuario = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, usuario: '5136186', perfil: 'Administrador', activo: true },
      { id: 2, usuario: '3391416', perfil: 'Chofer', activo: true }
    ]
  }
  responseMotivos = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, nombre: 'CORTE DE ENERGÍA', estado: true },
      { id: 2, nombre: 'FALLA DE EQUIPO', estado: true },
      { id: 3, nombre: 'FALLA DEL VEHÍCULO', estado: true }
    ]
  }
  responseUsuariosRoadshow = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, usuario: 'global', nombre: 'Sergio', apellido: 'Ballesteros', email: 'pana@gtp.com.py', estado: true },
      { id: 2, usuario: 'slaviosa', nombre: 'Sonia', apellido: 'Laviosa', email: 'sonia@gtp.com.py', estado: true },
      { id: 3, usuario: 'rsantos', nombre: 'Rodrigo', apellido: 'Santos', email: 'rodrigo@gtp.com.py', estado: true }
    ]
  }
  responseSupervisores = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, celular: '09871234567', nombre: 'Sergio', apellido: 'Ballesteros', email: 'pana@gtp.com.py', estado: true },
      { id: 2, celular: '09871234567', nombre: 'Sonia', apellido: 'Laviosa', email: 'sonia@gtp.com.py', estado: true },
      { id: 3, celular: '09871234567', nombre: 'Rodrigo', apellido: 'Santos', email: 'rodrigo@gtp.com.py', estado: true }
    ]
  }
  responseGrupos = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, codigo: '111', descripcion: 'Técnicos' },
      { id: 2, codigo: '112', descripcion: 'Supervisores' },
      { id: 3, codigo: '113', descripcion: 'Todos' }
    ]
  }
  responseGruposReferencias = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, nombre: 'Clientes', descripcion: 'Técnicos' },
      { id: 2, nombre: 'Técnicos', descripcion: 'Supervisores' },
      { id: 3, nombre: 'Supervisores', descripcion: 'Todos' }
    ]
  }
  responseArchivosCargados = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, nombre: '05.09.18.11.33.xls', tamanho: '188kb' },
      { id: 2, nombre: '05.09.18.12.22.xls', tamanho: '25kb' },
      { id: 3, nombre: '05.09.18.13.00.xls', tamanho: '88kb' }
    ]
  }
  responseArchivosGPXCargados = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, nombre: '05.09.18.11.33.xls', tamanho: '188kb' },
      { id: 2, nombre: '05.09.18.12.22.xls', tamanho: '25kb' },
      { id: 3, nombre: '05.09.18.13.00.xls', tamanho: '88kb' }
    ]
  }
  responseUbicarCalles = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, calle1: 'AVENIDA VENEZUELA', calle2: 'MIGUEL DE CERVANTES SAAVEDRA', pais: 'PARAGUAY', departamento: 'CENTRAL', ciudad: 'ASUNCION' },
      { id: 2, calle1: 'AVENIDA VENEZUELA', calle2: 'MIGUEL DE CERVANTES SAAVEDRA', pais: 'PARAGUAY', departamento: 'CENTRAL', ciudad: 'ASUNCION' },
      { id: 3, calle1: 'AVENIDA VENEZUELA', calle2: 'MIGUEL DE CERVANTES SAAVEDRA', pais: 'PARAGUAY', departamento: 'CENTRAL', ciudad: 'ASUNCION' }
    ]
  }
  responseAccesoSistema = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, usuario: 'global', fecha: '27/08/2017', hora: '10:30:00', ingreso: 'Acceso Exitos', ip: '127.0.0.1' },
      { id: 2, usuario: 'slaviosa', fecha: '27/08/2017', hora: '10:30:00', ingreso: 'Acceso Exitos', ip: '127.0.0.1' },
      { id: 3, usuario: 'rsantos', fecha: '27/08/2017', hora: '10:30:00', ingreso: 'Acceso Exitos', ip: '127.0.0.1' }
    ]
  }
  responseInformes = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, tecnico: 'Oscar Digno Ovelar Ruíz Díaz', fecha: '27/08/2017', tipo: 'Parcial', lugar: 'GTP', equipo: 'MÓVIL 081, POC 284, CONCEPCIÓN', servicio: 'SERVICE - DESCRIPCIÓN: Otras Aclaraciones: PULSADOR EXTRAÍDO Y CORTADO', recomendacion: 'FACTURAR PULSADOR DE PÁNICO', costo: 'PULSADOR DE PÁNICO: 125.000 Gs.-' },
      { id: 2, tecnico: 'Jose Domingo Campuzano Valdez', fecha: '27/08/2017', tipo: 'Parcial', lugar: 'GTP', equipo: 'MÓVIL 081, POC 284, CONCEPCIÓN', servicio: 'SERVICE - DESCRIPCIÓN: Otras Aclaraciones: PULSADOR EXTRAÍDO Y CORTADO', recomendacion: 'FACTURAR PULSADOR DE PÁNICO', costo: 'PULSADOR DE PÁNICO: 125.000 Gs.-' },
      { id: 3, tecnico: 'Pablo Cabrera', fecha: '27/08/2017', tipo: 'Parcial', lugar: 'GTP', equipo: 'MÓVIL 081, POC 284, CONCEPCIÓN', servicio: 'SERVICE - DESCRIPCIÓN: Otras Aclaraciones: PULSADOR EXTRAÍDO Y CORTADO', recomendacion: 'FACTURAR PULSADOR DE PÁNICO', costo: 'PULSADOR DE PÁNICO: 125.000 Gs.-' },
    ]
  }
  responsePersonal = {
    "recordsTotal": 50,
    "recordsFiltered": 10,
    "data": [
      { id: 1, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 2, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 3, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 4, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 5, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 6, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 7, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 8, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 9, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
      { id: 10, nombre: 'Sonia', apellido: 'Laviosa', cedula: '5136186', email: 'slaviosa@procesa.me', telefono: '021345678', celular1: '09871234567', celular2: '0971222333', obtener_informacion: 'si', contactar: 'si' },
    ]
  }

  responseDistanciaRecorrida = {
    "recordsTotal": 100,
    "recordsFiltered": 20,
    "data": [
      {
        "id": 1,
        "codvehiculo": 11647,
        "nombre": "R5G 5E0",
        "distancia": "49 Km.",
        "consumo": 89,
        "odometro": 24
      },
      {
        "id": 2,
        "codvehiculo": 13193,
        "nombre": "G9J 6B5",
        "distancia": "31 Km.",
        "consumo": 176,
        "odometro": 15
      },
      {
        "id": 3,
        "codvehiculo": 14141,
        "nombre": "Y9V 4O4",
        "distancia": "61 Km.",
        "consumo": 55,
        "odometro": 24
      },
      {
        "id": 4,
        "codvehiculo": 18636,
        "nombre": "C4X 6P8",
        "distancia": "86 Km.",
        "consumo": 107,
        "odometro": 3
      },
      {
        "id": 5,
        "codvehiculo": 12975,
        "nombre": "Z4P 8W6",
        "distancia": "66 Km.",
        "consumo": 121,
        "odometro": 24
      },
      {
        "id": 6,
        "codvehiculo": 14440,
        "nombre": "W0P 8A5",
        "distancia": "67 Km.",
        "consumo": 101,
        "odometro": 14
      },
      {
        "id": 7,
        "codvehiculo": 15055,
        "nombre": "H1C 2A0",
        "distancia": "19 Km.",
        "consumo": 128,
        "odometro": 5
      },
      {
        "id": 8,
        "codvehiculo": 13263,
        "nombre": "H7W 4V1",
        "distancia": "32 Km.",
        "consumo": 109,
        "odometro": 5
      },
      {
        "id": 8,
        "codvehiculo": 14581,
        "nombre": "R8J 6K7",
        "distancia": "18 Km.",
        "consumo": 137,
        "odometro": 6
      },
      {
        "id": 9,
        "codvehiculo": 16683,
        "nombre": "N8D 8Q8",
        "distancia": "69 Km.",
        "consumo": 122,
        "odometro": 20
      },
      {
        "id": 10,
        "codvehiculo": 11407,
        "nombre": "D8N 2W0",
        "distancia": "67 Km.",
        "consumo": 159,
        "odometro": 24
      },
      {
        "id": 11,
        "codvehiculo": 11616,
        "nombre": "K3O 7C2",
        "distancia": "33 Km.",
        "consumo": 57,
        "odometro": 23
      },
      {
        "id": 12,
        "codvehiculo": 13368,
        "nombre": "G4H 9E6",
        "distancia": "56 Km.",
        "consumo": 78,
        "odometro": 16
      },
      {
        "id": 13,
        "codvehiculo": 16001,
        "nombre": "E4R 0D7",
        "distancia": "48 Km.",
        "consumo": 107,
        "odometro": 29
      },
      {
        "id": 14,
        "codvehiculo": 11400,
        "nombre": "B8T 7M5",
        "distancia": "99 Km.",
        "consumo": 114,
        "odometro": 19
      },
      {
        "id": 15,
        "codvehiculo": 17684,
        "nombre": "I7R 1J9",
        "distancia": "25 Km.",
        "consumo": 169,
        "odometro": 10
      },
      {
        "id": 16,
        "codvehiculo": 14942,
        "nombre": "D9V 5I1",
        "distancia": "96 Km.",
        "consumo": 186,
        "odometro": 17
      },
      {
        "id": 17,
        "codvehiculo": 14444,
        "nombre": "O1I 3X6",
        "distancia": "37 Km.",
        "consumo": 104,
        "odometro": 29
      },
      {
        "id": 18,
        "codvehiculo": 16939,
        "nombre": "U3L 1J3",
        "distancia": "1, Km.",
        "consumo": 195,
        "odometro": 28
      },
      {
        "id": 19,
        "codvehiculo": 19142,
        "nombre": "L9P 9H1",
        "distancia": "9, Km.",
        "consumo": 55,
        "odometro": 28
      },
      {
        "id": 20,
        "codvehiculo": 14953,
        "nombre": "Y3T 4R1",
        "distancia": "18 Km.",
        "consumo": 95,
        "odometro": 13
      },
      {
        "id": 21,
        "codvehiculo": 19002,
        "nombre": "P0E 6F0",
        "distancia": "36 Km.",
        "consumo": 54,
        "odometro": 20
      },
      {
        "id": 22,
        "codvehiculo": 10090,
        "nombre": "C7Q 4K0",
        "distancia": "16 Km.",
        "consumo": 186,
        "odometro": 2
      },
      {
        "id": 23,
        "codvehiculo": 19163,
        "nombre": "A2L 1C1",
        "distancia": "16 Km.",
        "consumo": 61,
        "odometro": 18
      },
      {
        "id": 24,
        "codvehiculo": 15969,
        "nombre": "T0R 6D8",
        "distancia": "69 Km.",
        "consumo": 180,
        "odometro": 1
      },
      {
        "id": 25,
        "codvehiculo": 18571,
        "nombre": "K2K 6O7",
        "distancia": "24 Km.",
        "consumo": 55,
        "odometro": 16
      },
      {
        "id": 26,
        "codvehiculo": 18461,
        "nombre": "Q5C 4F6",
        "distancia": "82 Km.",
        "consumo": 170,
        "odometro": 7
      },
      {
        "id": 27,
        "codvehiculo": 14870,
        "nombre": "E5W 5X4",
        "distancia": "6, Km.",
        "consumo": 102,
        "odometro": 6
      },
      {
        "id": 28,
        "codvehiculo": 10423,
        "nombre": "Z3N 3R0",
        "distancia": "41 Km.",
        "consumo": 69,
        "odometro": 9
      },
      {
        "id": 29,
        "codvehiculo": 18459,
        "nombre": "X1W 2Z1",
        "distancia": "47 Km.",
        "consumo": 70,
        "odometro": 13
      },
      {
        "id": 30,
        "codvehiculo": 19883,
        "nombre": "K5C 8L0",
        "distancia": "31 Km.",
        "consumo": 183,
        "odometro": 2
      },
      {
        "id": 31,
        "codvehiculo": 10864,
        "nombre": "P0F 4K2",
        "distancia": "16 Km.",
        "consumo": 103,
        "odometro": 1
      },
      {
        "id": 32,
        "codvehiculo": 16435,
        "nombre": "I9T 0J8",
        "distancia": "21 Km.",
        "consumo": 164,
        "odometro": 15
      },
      {
        "id": 33,
        "codvehiculo": 18470,
        "nombre": "K7H 4E1",
        "distancia": "39 Km.",
        "consumo": 166,
        "odometro": 22
      },
      {
        "id": 34,
        "codvehiculo": 12068,
        "nombre": "J3N 4X2",
        "distancia": "75 Km.",
        "consumo": 161,
        "odometro": 13
      },
      {
        "id": 35,
        "codvehiculo": 18662,
        "nombre": "N9R 2P6",
        "distancia": "75 Km.",
        "consumo": 192,
        "odometro": 29
      },
      {
        "id": 36,
        "codvehiculo": 11138,
        "nombre": "Z1R 3I6",
        "distancia": "83 Km.",
        "consumo": 167,
        "odometro": 15
      },
      {
        "id": 37,
        "codvehiculo": 19336,
        "nombre": "J3G 0T5",
        "distancia": "85 Km.",
        "consumo": 171,
        "odometro": 16
      }
    ]
  }
  responseControlVelocidad = {
    "recordsTotal": 20,
    "recordsFiltered": 10,
    "data": [
      {
        "id": 1,
        "codvehiculo": "10001",
        "nombre": "10001 - VOLVO AFB-078 ACARREO 988",
        "fecha": "2018-08-21T15:23:46.127Z",
        "direccion": "AVDA E VON POLESKI y AVDA E VON POLESKI, CENTRAL BARRIO: PICADA DISTRITO: VILLA ELISA",
        "velocidad": "100 Km/h",
        "latitud": "-25.505270",
        "longitud": "-54.344564"
      },
      {
        "id": 2,
        "codvehiculo": "10001",
        "nombre": "10001 - VOLVO AFB-078 ACARREO 988",
        "fecha": "2018-08-21T15:23:46.127Z",
        "direccion": "AVDA E VON POLESKI y AVDA E VON POLESKI, CENTRAL BARRIO: PICADA DISTRITO: VILLA ELISA",
        "velocidad": "80 Km/h",
        "latitud": "-25.505270",
        "longitud": "-54.775810"
      },
      {
        "id": 3,
        "codvehiculo": "10035",
        "nombre": "10035 - VOLVO AFB-078 ACARREO 988",
        "fecha": "2018-08-21T15:23:46.127Z",
        "direccion": "AVDA E VON POLESKI y AVDA E VON POLESKI, CENTRAL BARRIO: PICADA DISTRITO: VILLA ELISA",
        "velocidad": "100 Km/h",
        "latitud": "-25.272745",
        "longitud": "-57.584613"
      },
      {
        "id": 4,
        "codvehiculo": "10035",
        "nombre": "10035 - VOLVO AFB-078 ACARREO 988",
        "fecha": "2018-08-21T15:23:46.127Z",
        "direccion": "AVDA E VON POLESKI y AVDA E VON POLESKI, CENTRAL BARRIO: PICADA DISTRITO: VILLA ELISA",
        "velocidad": "50 Km/h",
        "latitud": "-25.505270",
        "longitud": "-54.775810"
      },
      {
        "id": 5,
        "codvehiculo": "10065",
        "nombre": "10065 - VOLVO AFB-078 ACARREO 988",
        "fecha": "2018-08-21T15:23:46.127Z",
        "direccion": "AVDA E VON POLESKI y AVDA E VON POLESKI, CENTRAL BARRIO: PICADA DISTRITO: VILLA ELISA",
        "velocidad": "100 Km/h",
        "latitud": "-25.505270",
        "longitud": "-54.775810"
      }
    ]
  }

  constructor(
    public router: Router,
    public _rastreo: RastreoService,
    public _http: Http,
    public constant: Constant
  ) {

  }

  ngAfterViewInit() {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.clearTable();
  }

  setPage(pageNo: number): void {
    //this.config.currentPage = pageNo;    
  }

  pageChanged(event: any): void {
    //console.log('this.totalItems: '+this.config.totalItems);
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
  }

  //Realiza las acciones de los botones
  doAction(row) {
    var btnrouter = $('.btn-accion', row);
    btnrouter.bind('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      //Verifico si es un router Link        
      let routerLink = (btnrouter.attr("data-routerLink")) ? (btnrouter.attr("data-routerLink")) : false;
      if (routerLink) { this.router.navigate([routerLink]); }
      //Verifico si es un modal
      let showmodal = (btnrouter.attr("data-showmodal")) ? (btnrouter.attr("data-showmodal")) : false;
      if (showmodal) { this.msgModal.next(showmodal); }
    });
  }

  //Limpia la tabla
  //Quito la tabla de la memoria
  clearTable() {
    if (this.datatable != null) {
      this.datatable.destroy();
      $('#tablaID').empty();
      $(".infomain").removeClass("bgwhite");
    }
  }

  addElement() {
    this.datatable.row.add({
      "codvehiculo": null,
      "nombre": "AGREGADO",
      "distancia": null,
      "consumo": null,
      "odometro": null
    }).draw();
  }

  setData(data) {
    this.filtros = data.filtro;
    this.tableId = `tablaID_${data.tablaId}`;
    this.tableData = data;
    this.setTable();
  }
  //Configura las tablas
  setTable() {
    this.clearTable();
    this.datatable = $('#tablaID').DataTable(this.configTable());
    $(".infomain").addClass("bgwhite");
  }

  //Configuración de las tablas
  configTable() {
    const options = this.constant.options;
    console.log(this.tableData);

    //Trabajamos con los datos de la Columna
    let config = this.tableData;
    //Nombre del archivo a exportar
    let filename = config.filename;

    //logo Contenido Footer Izquierdo
    let footright = "Generado por: " + config.user_g + " - Más información en: www.gtp.com.py";
    //Configuración para agrupar elementos basados en una columna
    let Agrupar = config.Agrupar;
    //logo Header
    let logo = environment.logoPdf;
    //Fecha Actual - Firma de Reportes
    let fecha = this._rastreo.formatDate(this._rastreo.DiaHoraActual());
    //configura el endpoint a referenciar
    let endPoint = config.endpoint;
    //Agrega filtrado
    let filtro = this.filtros;
    //Definición de columnas
    let columnDefs = [
      {
        visible: true,
        //Da prioridad a los botones de acciones para que sean activos en responsive.
        responsivePriority: config.actionbtn,
        //Botones de acciones para que sean activos en responsive.
        targets: config.actionbtn,
        render: function (data, type, row) {
          return data;
        }
      },
      {
        //Centra los elementos de las columnas
        className: "dt-center",
        targets: "_all"
      }
    ];
    //Devuelve los valores
    return {
      bAutoWidth: false,
      columns: config.t_columns,
      dom: 'lfrBtip',
      language: { url: "/assets/i18/Spanish.json" },
      lengthChange: true,
      lengthMenu: [[10, 15, 25, 50, -1], [10, 15, 25, 50, "Todos"]],
      pageLength: 10,
      paging: true,
      processing: true,
      responsive: true,
      serverSide: true,
      ajax: (dataTablesParameters: any, callback) => {
        let urlPath = environment.urlApiCheckoutMovil + endPoint; //TODO cambiar luego que se tenga este servicio en el de rastreo
        dataTablesParameters.filtro = filtro;
        //console.log("dataTablesParameters.filtro: ", dataTablesParameters.filtro);
        const headers = new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer aASXASDASDS'
        });
        // TODO reemplezar cuando se tengan los endpoints correspondientes.
        let result;
        if (endPoint == 'responseDistanciaRecorrida') {
          result = this.responseDistanciaRecorrida;
        }
        if (endPoint == 'paradas') {
          result = this.response;
        }
        if (endPoint == 'informe') {
          result = this.response;
        }
        if (endPoint == 'control-velocidad') {
          result = this.responseControlVelocidad;
        }
        if (endPoint == 'personal') {
          result = this.responsePersonal;
        }
        if (endPoint == 'usuarios') {
          result = this.responseUsuarios;
        }
        if (endPoint == 'usuariosRoadshow') {
          result = this.responseUsuariosRoadshow;
        }
        if (endPoint == 'accesoSistema') {
          result = this.responseAccesoSistema;
        }
        if (endPoint == 'informes') {
          result = this.responseInformes;
        }
        if (endPoint == 'motivo') {
          result = this.responseMotivos;
        }
        if (endPoint == 'supervisores') {
          result = this.responseSupervisores;
        }
        if (endPoint == 'grupos') {
          result = this.responseGrupos;
        }
        if (endPoint == 'gruposReferencias') {
          result = this.responseGruposReferencias;
        }
        if (endPoint == 'importarPuntos') {
          result = this.responseArchivosCargados;
        }
        if (endPoint == 'importarPuntosGPX') {
          result = this.responseArchivosGPXCargados;
        }
        if (endPoint == 'importarPuntosXLSRoadshow') {
          result = this.responseArchivosGPXCargados;
        }
        if (endPoint == 'ubicarCalles') {
          result = this.responseUbicarCalles;
        }
        if (endPoint == 'checkoutMovilUsuario') {
          result = this.responseCheckoutMovilUsuario;
        }
        if (endPoint == 'usuario/movil' || endPoint == 'vehiculo/item' || endPoint == 'vehiculo/documento' || endPoint == 'vehiculo/item/puntuado' || endPoint == 'vehiculo/item/puntuacion/tipo' || endPoint == 'vehiculo/item/puntuacion') {

          this._http.post(urlPath, dataTablesParameters, {})
            .subscribe(
              resp => {
                let result = resp.json();
                if (result.data) {
                  callback({
                    recordsTotal: result.paginacion.total_registro,
                    recordsFiltered: result.paginacion.total_registro,
                    data: result.data
                  });
                } else {
                  callback({
                    recordsTotal: 0,
                    recordsFiltered: 0,
                    data: []
                  });
                }

              },
              err => {
                console.log("Error occured");
              }
            );
        } else {
          callback({
            recordsTotal: result.recordsTotal,
            recordsFiltered: result.recordsFiltered,
            data: result.data
          });
        }

        // callback({
        //   recordsTotal: result.recordsTotal,
        //   recordsFiltered: result.recordsFiltered,
        //   data: result.data
        // });
        // const options = new RequestOptions();
        // this._http.post(urlPath, { dataTablesParameters, options, headers })
        //   .subscribe(
        //     resp => {
        //       let result = resp.json();
        //       callback({
        //         recordsTotal: result.recordsTotal,
        //         recordsFiltered: result.recordsFiltered,
        //         data: result.data
        //       });
        //     },
        //     err => {
        //       console.log("Error occured");
        //     }
        //   );
      },
      initComplete: function (settings, json) {
        $('.dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_filter button, .dataTables_length select').addClass('form-control');
        $('#tablaID thead tr th').addClass('tableHeader');
      },
      rowCallback: (row: Node, data: any | Object, index: number) => {
        //Genera las acciones de los botones
        console.log('row: ', row)
        this.doAction(row);
        return row;
      },
      columnDefs: columnDefs,
      drawCallback: function (settings) {
        //Para agrupar por columnas
        if (config.agrupar) {
          var api = this.api();
          var rows = api.rows({ page: 'current' }).nodes();
          var last = null;
          api.column(config.htarget, { page: 'current' }).data().each(function (group, i) {
            if (last !== group) {
              $(rows).eq(i).before('<tr class="group t-group-header"><td colspan="5">' + group + '</td></tr>');
              last = group;
            }
          });
        }
      },
      buttons: [
        {
          className: 'btn btn-round buttons-html5 color-green',
          exportOptions: { columns: config.exp_columns },
          extend: 'excel',
          title: filename,
          init: function (api, node, config) {
            $(node).removeClass('dt-button')
          },
          footer: true
        },
        {
          className: 'btn btn-round buttons-html5 color-red',
          exportOptions: {
            columns: config.exp_columns,
            search: 'applied',
            order: 'applied'
          },
          extend: 'pdfHtml5',
          bProcessing: true,
          orientation: 'landscape',
          pageSize: 'A4', //A3 , A5 , A6 , legal , letter
          title: filename,
          footer: true,
          header: {
            margin: 10,
            columns: [
              {
                image: logo,
                width: 40
              }
            ]
          },
          init: function (api, node, config) {
            $(node).removeClass('dt-button');
          },
          customize: function (doc) {
            //Remove the title created by datatTables
            doc.content.splice(0, 1);
            // Set page margins [left,top,right,bottom] or [horizontal,vertical]
            doc.pageMargins = [20, 60, 20, 30];
            // Set the font size fot the entire document
            doc.defaultStyle.fontSize = 7;
            // Set the fontsize for the table header
            doc.styles.tableHeader.fontSize = 7;
            // Create a header object with 3 columns
            // Left side: Logo - Middle: brandname - Right side: A document title
            doc['header'] = (function () {
              return {
                columns: [
                  {
                    image: logo,
                    width: 80
                  },
                  {
                    alignment: 'left',
                    italics: true,
                    text: filename,
                    fontSize: 14,
                    margin: [10, 0]
                  },
                  {
                    alignment: 'left',
                    margin: [10, 0, 0, 0],
                    fontSize: 10,
                    text: (config.headerPdf != '') ? config.headerPdf : ''
                  },
                  {
                    alignment: 'right',
                    fontSize: 12,
                    text: fecha
                  }
                ],
                margin: 20
              }
            });
            // Create a footer object with 2 columns
            // Left side: report creation date - Right side: current page and total pages
            doc['footer'] = (function (page, pages) {
              return {
                columns: [
                  {
                    alignment: 'left',
                    text: [footright]
                  },
                  {
                    alignment: 'right',
                    text: ['Página ', { text: page.toString() }, ' de ', { text: pages.toString() }]
                  }
                ],
                // Set page margins [left,top,right,bottom] or [horizontal,vertical]
                margin: [20, 5, 20, 20]
              }
            });
            // Change dataTable layout (Table styling)
            // To use predefined layouts uncomment the line below and comment the custom lines below
            // doc.content[0].layout = 'lightHorizontalLines'; // noBorders , headerLineOnly
            var objLayout = {};
            objLayout['hLineWidth'] = function (i) { return .5; };
            objLayout['vLineWidth'] = function (i) { return .5; };
            objLayout['hLineColor'] = function (i) { return '#aaa'; };
            objLayout['vLineColor'] = function (i) { return '#aaa'; };
            objLayout['paddingLeft'] = function (i) { return 4; };
            objLayout['paddingRight'] = function (i) { return 4; };
            doc.content[0].layout = objLayout;
          }
        },
        {
          className: 'btn btn-round buttons-html5 color-dark-blue',
          exportOptions: { columns: config.exp_columns },
          extend: 'print',
          text: 'Imprimir',
          title: filename,
          init: function (api, node, config) {
            $(node).removeClass('dt-button')
          },
          footer: true
        }
      ]
    }
  }
}
