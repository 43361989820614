//angular
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../auth/auth.guard';
import { InformeComponent } from './components';
//components

const informeRoutes: Routes = [
    { path: 'movil', children: [
        {
            path: 'informe/:id',
            component: InformeComponent,
            canActivate: [AuthGuard]
        }]
    }
];

export const informeRouting = RouterModule.forChild(informeRoutes);