import { Component, OnInit } from '@angular/core';

import { RastreoService } from '../../services/rastreo.service';
import { SidepanelsService } from '../../rastreo/services/sidepanels.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: {
    class: 'col-lg-2 col-md-2 col-sm-12 sideInfo p-0'
  }
})
export class SidebarComponent implements OnInit {

  panel:any;     // Contenedor Medidas para los paneles Izq, Der, Main  
  sideHeight:any; //Alto del SidebarLeft
  //Indicador de Cargando
  loader:any = {
    panel_left: false
  };

  menuList: any[];
  constructor(
    private _rastreo:RastreoService,
    private _panel:SidepanelsService
  ){
    this.panel = this._panel.getPanelInfo();
  }

  ngOnInit() {
    this.sideHSize();
    let that = this;
    window.onresize = function(){ that.sideHSize(); };
    this.getMenu();
  }

  //Altura del sideBar
  sideHSize(){
    //this.sideHeight = window.innerHeight-this._rastreo.hHeight;
    this.sideHeight = window.innerHeight;
  }

  /**
   * Función para obtener el listado de menús del sistema.
   */
  getMenu() {
    // TODO cambiar por el servicio cuando se tenga el endpoint.
    this.menuList = 
    [
      { "id":"19","label":"Control de Flota","icon":"transportation_bus-front-12","end_point":null,"parent_id":null,"sequence":0,
        "sub_menus":
        [
          {"id":"9","label":"Ver Toda la Flota","icon":null,"end_point":"control/flota/ver","parent_id":19,"sequence":0, "siglas":"VF"},
          {"id":"1","label":"Control de Velocidades","icon":null,"end_point":"control/flota/velocidad","parent_id":19,"sequence":1, "siglas":"CV"},
          {"id":"2","label":"Distancia Recorrida","icon":null,"end_point":"control/flota/distancia/recorrida","parent_id":19,"sequence":2, "siglas":"DR"}
        ]
      },
      {"id":"6","label":"Administración","icon":"ui-1_settings-gear-63","end_point":null,"parent_id":null,"sequence":1,
        "sub_menus":
        [
          {"id":"34","label":"Vehículos","icon":null,"end_point":"administracion/vehiculos/lista","parent_id":6,"sequence":1, "siglas":"V"},
          {"id":"35","label":"Usuarios","icon":null,"end_point":"administracion/usuarios/lista","parent_id":6,"sequence":2, "siglas":"U"},
          {"id":"40","label":"Usuarios Roadshow","icon":null,"end_point":"administracion/usuarios/roadshow/lista","parent_id":6,"sequence":3, "siglas":"UR"},
          {"id":"42","label":"Supervisores","icon":null,"end_point":"administracion/supervisores/lista","parent_id":6,"sequence":7, "siglas":"S"},
          {"id":"36","label":"Grupos","icon":null,"end_point":"administracion/grupos/lista","parent_id":6,"sequence":4, "siglas":"VF"},
          {"id":"37","label":"Choferes","icon":null,"end_point":"administracion/choferes/lista","parent_id":6,"sequence":5, "siglas":"CH"},
          {"id":"39","label":"Acceso al Sistema","icon":null,"end_point":"administracion/acceso/sistema/lista","parent_id":6,"sequence":5, "siglas":"AS"},
          {"id":"41","label":"Motivos no Entrega","icon":null,"end_point":"administracion/motivo/lista","parent_id":6,"sequence":8, "siglas":"NE"},
          {"id":"39","label":"Informes","icon":null,"end_point":"administracion/informes/lista","parent_id":7,"sequence":5, "siglas":"I"}
        ]
      },
      {"id":"7","label":"Herramientas","icon":"ui-2_settings-90","end_point":null,"parent_id":null,"sequence":1,
        "sub_menus":
        [
          {"id":"34","label":"Grupos de Referencias","icon":null,"end_point":"herramienta/grupo/referencia/lista","parent_id":6,"sequence":1, "siglas":"GR"},
          {"id":"35","label":"Crear Puntos","icon":null,"end_point":"user/processor/list","parent_id":6,"sequence":2, "siglas":"CP"},
          {"id":"36","label":"Mis Puntos","icon":null,"end_point":"user/entity/list","parent_id":6,"sequence":3, "siglas":"MP"},
          {"id":"37","label":"Rutas","icon":null,"end_point":"user/commerce/list","parent_id":6,"sequence":4, "siglas":"RU"},
          {"id":"38","label":"Mis Rutas","icon":null,"end_point":"user/login/log/list","parent_id":6,"sequence":5, "siglas":"MR"},
          {"id":"39","label":"Mis Capas","icon":null,"end_point":"user/login/log/list","parent_id":6,"sequence":5, "siglas":"MC"},
          {"id":"40","label":"Mis Geocercas","icon":null,"end_point":"user/login/log/list","parent_id":6,"sequence":5, "siglas":"MG"},
          {"id":"41","label":"Archivo Roadshow","icon":null,"end_point":"herramienta/punto/importar/lista/roadshow/xls","parent_id":6,"sequence":5, "siglas":"AR"},
          {"id":"42","label":"Importar Puntos","icon":null,"end_point":"herramienta/punto/importar/lista/xls","parent_id":6,"sequence":5, "siglas":"IP"},
          {"id":"43","label":"Importar GPX","icon":null,"end_point":"herramienta/punto/importar/lista/gpx","parent_id":6,"sequence":5, "siglas":"IG"},
          {"id":"44","label":"Ubicar Calles","icon":null,"end_point":"herramienta/punto/ubicar/calle","parent_id":6,"sequence":5, "siglas":"UC"} 
        ]
      },
      {"id":"8","label":"Checkout Móvil","icon":"education_paper","end_point":null,"parent_id":null,"sequence":1,
        "sub_menus":
        [
          {"id":"34","label":"Usuario","icon":null,"end_point":"checkout/movil/usuario/lista","parent_id":6,"sequence":1, "siglas":"U"},
          {"id":"36","label":"Puntuación","icon":null,"end_point":"checkout/movil/puntuacion/lista","parent_id":6,"sequence":3, "siglas":"P"},
          {"id":"37","label":"Item","icon":null,"end_point":"checkout/movil/item/lista","parent_id":6,"sequence":4, "siglas":"I"},
          {"id":"38","label":"Documento","icon":null,"end_point":"checkout/movil/documento/lista","parent_id":6,"sequence":5, "siglas":"D"},
          {"id":"39","label":"Historial","icon":null,"end_point":"checkout/movil/historial/lista","parent_id":6,"sequence":6, "siglas":"H"}
        ]
      }
    ]
  }
}
