import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';


@Component({
    selector: 'app-supervisores',
    templateUrl: '../templates/supervisores.component.html'
})
export class SupervisoresComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Supervisores`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `supervisores`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'nombre',
                data: 'nombre',
                title: "Nombre",
                width: "20%",
                className: "dt-center"
            },
            {
                name: 'apellido',
                data: 'apellido',
                title: "Apellido",
                width: "20%"
            },
            {
                name: 'celular',
                data: 'celular',
                title: "Celular",
                width: "20%"
            },
            {
                name: 'email',
                data: 'email',
                title: "Email",
                width: "20%"
            },
            {
                name: 'estado',
                title: "Estado",
                width: "10%",
                render: function (data: any, type: any, row: any, meta) {
                    let html;
                    if (row.estado) {
                        html = `<span>Activo</span>`;
                    } else {
                        html = `<span>Inactivo</span>`
                    }
                    return html;
                }
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "10%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="Editar" data-toggle="modal" data-target="#editarSupervisor${row.id}">
                        <i class="now-ui-icons ui-2_settings-90"></i>
                    </button>
                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="Ver Móviles" data-toggle="modal" data-target="#verVehiculo${row.id}">
                        <i class="fas fa-bus"></i>
                    </button>
                    <div class="modal fade" id="verVehiculo${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title">Móviles a Cargo de ${row.nombre} ${row.apellido}</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="table-responsive table-style">
                                        <table class="table">
                                            <thead>
                                                <th class="text-primary">
                                                    Nro. Móvil
                                                </th>
                                                <th class="text-primary">
                                                    Descripción
                                                </th>
                                                <th class="text-primary">
                                                    Estado
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        3
                                                    </td>
                                                    <td>
                                                        NISSAN BGD-620 GTP SRL
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        4
                                                    </td>
                                                    <td>
                                                        KENTON, GL 125, NEGRO, GTP ENC, CHASIS (1614)
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input class="form-check-input" type="checkbox">
                                                                <span class="form-check-sign"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-info btn-round" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="editarSupervisor${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title">Editar Supervisor</h4>
                                </div>
                                <div class="modal-body">
                                            <div class="row sepform">
                                                <div class="col">
                                                    <label>
                                                        Nombre
                                                    </label>
                                                    <input class="form-control bl-1" name="nombre" value="${row.nombre}" [(ngModel)]="${row.nombre}" type="text" required="true" />
                                                </div>
                                                <div class="col">
                                                    <label>
                                                        Apellido
                                                    </label>
                                                    <input class="form-control bl-1" name="aplellido" value="${row.aplellido}" type="text" required="true" />
                                                </div>
                                            </div>
                                            <div class="row sepform">
                                                <div class="col">
                                                    <label>
                                                        Número de Documento
                                                    </label>
                                                    <input class="form-control bl-1" name="documento" type="number" required="true" />
                                                </div>
                                                <div class="col">
                                                    <label>
                                                        Celular
                                                    </label>
                                                    <input class="form-control bl-1" name="celular" value="${row.celular}" type="number" required="true" />
                                                </div>
                                            </div>
                                            <div class="row sepform">
                                                <div class="col">
                                                    <label>
                                                        Email
                                                    </label>
                                                    <input class="form-control bl-1" name="email" value="${row.email}" type="email" required="true" />
                                                </div>
                                                <div class="col">
                                                    <div class="form-check not-padding">
                                                        <label class="form-check-label">
                                                            Estado
                                                            <input class="form-check-input" type="checkbox" value="${row.estado}" checkout="${row.nombre}">
                                                            <span class="form-check-sign"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row sepform">
                                                <div class="col">
                                                    <label>
                                                        Código Región
                                                    </label>
                                                    <input class="form-control bl-1" name="codRegion" type="number" required="true" />
                                                </div>
                                                <div class="col">
                                                    <label>
                                                        Cantidad Mínima de Bultos Región
                                                    </label>
                                                    <input class="form-control bl-1" name="cantidadMin" type="number" required="true" />
                                                </div>
                                            </div>
                                            <div class="row sepform">
                                                <div class="col">
                                                    <label>
                                                        Código Distrito
                                                    </label>
                                                    <input class="form-control bl-1" name="codDistrito" type="number" required="true" />
                                                </div>
                                                <div class="col">
                                                    <label>
                                                        Cantidad Mínima de Bultos Distrito
                                                    </label>
                                                    <input class="form-control bl-1" name="cantidadMinDistrito" type="number" required="true" />
                                                </div>
                                            </div>
                                            <div class="row sepform">
                                                <div class="col">
                                                    <label>
                                                        Código Zona
                                                    </label>
                                                    <input class="form-control bl-1" name="codZona" type="number" required="true" />
                                                </div>
                                                <div class="col">
                                                    <label>
                                                        Cantidad Mínima de Bultos Zona
                                                    </label>
                                                    <input class="form-control bl-1" name="cantidadMinZona" type="number" required="true" />
                                                </div>
                                            </div>
                                        </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-info btn-round" data-dismiss="modal">Agregar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Administración de Supervisores`);
    }

    ngOnInit() {
        this.obtenerSupervisores();
    }


    obtenerSupervisores() {
        this.tabla.setData(this.configTable);
    }
}
