//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablaComponent } from './infotabla.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
    ],
    declarations: [
        TablaComponent
    ],
    providers: [
        
    ],
    exports: [
        TablaComponent
    ]
})

export class TablaModule { }