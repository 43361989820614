//angular
import { Routes, RouterModule } from '@angular/router';
import { VehiculosComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const vehiculosRoutes: Routes = [
    {
        path: 'administracion/vehiculos',
        children: [
            {
                path: 'lista',
                component: VehiculosComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const vehiculosRouting = RouterModule.forChild(vehiculosRoutes);