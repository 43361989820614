//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfilComponent } from './components';
import { perfilRouting } from './perfil.routing';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        perfilRouting,
        SharedModule
    ],
    declarations: [
        PerfilComponent
    ],
    providers: [

    ],
    exports: [
        PerfilComponent
    ]
})

export class PerfilModule { }