//angular
import { Routes, RouterModule } from '@angular/router';
import { MotivoComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const motivoRoutes: Routes = [
    {
        path: 'administracion/motivo',
        children: [
            {
                path: 'lista',
                component: MotivoComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const motivoRouting = RouterModule.forChild(motivoRoutes);