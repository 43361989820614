import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { RastreoService } from '../../../../services/rastreo.service';
import { SidepanelsService } from '../../../services/sidepanels.service';
import { Gtp } from '../../../../constants/gtp';

declare const $;

@Component({
  selector: 'app-distancia-recorrida',
  templateUrl: '../templates/distancia-recorrida.component.html',
  styleUrls: ['../templates/distancia-recorrida.component.scss']
})

export class DistanciaRecorridaComponent implements OnInit {

  @ViewChild(TablaComponent) tabla: TablaComponent;

  //Datos de Lenguaje del DateTime Picker
  fechalang: any;
  // máxima fecha para la fecha desde
  maxFechaDesde = new Date();
  // máxima fecha para la fecha hasta
  maxFechaHasta = new Date();
  fechaHoraDesde;
  fechaHoraHasta;
  //Valores Fecha Desde / Hasta
  filtro: any = {
    fdesde: Date,
    fhasta: Date
  };

  tableData: any;
  configTable: any = {
    //Nombre del archivo a Exportar
    filename: "Distancias Recorrida por Móviles",
    //Usuario Genero PDF
    user_g: "",
    //Totales top
    headerPdf: `Distancia Total: 130 litros \n Consumo Promedio: 1.768km \n Odometro Total: 200`,
    //Link endPoint
    endpoint: `responseDistanciaRecorrida`,
    //Configuración de columnas    
    t_columns: [
      {
        name: 'codvehiculo',
        data: 'codvehiculo',
        title: "Id",
        width: "5%",
        className: "dt-center"
      },
      {
        name: 'nombre',
        data: 'nombre',
        title: "Nombre",
        width: "20%"
      },
      {
        name: 'distancia',
        data: 'distancia',
        title: "Distancia",
        width: "8%",
        className: "dt-center"
      },
      {
        name: 'consumo',
        data: 'consumo',
        title: "Consumo Promedio",
        width: "12%",
        className: "dt-center"
      },
      {
        name: 'odometro',
        data: 'odometro',
        title: "Odometro Total",
        width: "10%",
        className: "dt-center"
      }
    ],
    // Columnas a exportar
    exp_columns: [0, 1, 2, 3, 4],
    // Columa que debe llevar al header
    htarget: null,
    // Columna con botones de acciones
    actionbtn: null,
    // Agrupa o no (Deja un header arriba)  
    agrupar: false,
  }

  mainClass: any;
  subscription: Subscription;

  constructor(
    public _rastreo: RastreoService,
    public gtp: Gtp) {
    //Trae la fecha inicial del día
    this.obtenerFechaInicial();
    //Trae la fecha con idioma español
    this.fechalang = this._rastreo.fechalang;
    //setea el título de la cabecera principal
    this.gtp.setTituloCabecera('Distancia Recorrida');
  }

  ngOnInit() {
    this.obtenerDistanciasRecorridasPorFiltro();
  }

  ngAfterViewInit() {
  }

  /**
   * Función para obtener la Fecha del día para utilizar en el filtro.
   */
  obtenerFechaInicial() {
    this.fechaHoraDesde = this._rastreo.DiaHoraActual();
    this.fechaHoraDesde.setHours(0, 0, 0, 0);
    this.fechaHoraHasta = this._rastreo.DiaHoraActual();
  }
  /**
   * Funcion para setear los valores de los filtros en la tabla para poderlos filtrar por eso datos.
   */
  setFiltros() {
    this.tabla.filtros = {
      fdesde: this.fechaHoraDesde,
      fhasta: this.fechaHoraHasta
    };
  }

  /**
   * Función para obtener la lista con filtro y paginado de la distancia recorrida de toda la flota
   */
  obtenerDistanciasRecorridasPorFiltro() {
    //seteamos los datos del filtro
    this.setFiltros();
    // obtenemos los móviles
    this.tabla.setData(this.configTable);
  }

}
