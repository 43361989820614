import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';

@Injectable()
export class Gtp {
    tituloCabecera: string;

    constructor(public http: Http, public router: Router) {

    }

    getItemLocalStorage(name: string): string {
        return localStorage.getItem(name);
    }

    setItemLocalStorage(name: string, obj: Object) {
        localStorage.setItem(name, JSON.stringify(obj));
    }

    removeItemLocalStorage(name: string) {
        localStorage.removeItem(name);
    }

    obtenerUsuarioActual() {
        return JSON.parse(this.getItemLocalStorage('currentUser'));
    }

    obtenerFechaActual() {
        let fecha = new Date();
        console.log(fecha.getMonth());
    }

    obtenerTituloCabecera() {
        return this.tituloCabecera;
    }
    setTituloCabecera(tituloCabecera) {
        this.tituloCabecera = tituloCabecera;
    }
    static formatearFechaHora(fecha: any): any {
        let f = this.setDate(fecha);
        let h = this.getHourToString(fecha)
        return `${f} ${h}`;
    }

    /**
     * 
     * @param f 
     * Solucion para el problema de cambio de fecha en el formateo angular.
     */
    static setDate(f: string) {

        let fecha = new Date(f);
        let fecha2 = `${fecha.getUTCDate() <= 9 ? 0 : ''}${fecha.getUTCDate()}/${fecha.getUTCMonth() + 1 <= 9 ? 0 : ''}${fecha.getUTCMonth() + 1}/${fecha.getUTCFullYear()}`;
        return fecha2;
    }

    /**
     * Función para retornar la hora en string de una fecha.
     * @param date fecha en string
     */
    static getHourToString(date: string) {
        let d = new Date(date);
        let h = this.setHour(d)//`${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}`;
        return h;
    }

    static setHour(fecha) {
        return `${('0' + fecha.getHours()).slice(-2)}:${('0' + fecha.getMinutes()).slice(-2)}:${('0' + fecha.getSeconds()).slice(-2)}`;
    }

}