import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { RastreoService } from '../../../services/rastreo.service';
import { TablaComponent } from '../../../shared/infotabla/infotabla.component';

declare const $;

@Component({
  selector: 'app-primer-movimiento',
  templateUrl: './primer-movimiento.component.html',
  styleUrls: ['./primer-movimiento.component.scss']
})

export class PrimerMovimientoComponent implements OnInit { 

  @ViewChild(TablaComponent) tabla:TablaComponent;

  //Datos de Lenguaje del DateTime Picker
  fechalang:any;  
  //Valores Fecha Desde / Hasta
  filtro:any = {
    dia: '',
    mes: ''
  };

  tableData:any;
  configTable:any = {
    //Nombre del archivo a Exportar
    filename : "Primer Movimiento del día",
    //Usuario Genero PDF
    user_g : "",
    //Totales top
    headerPdf : ``,
    //Link endPoint
    endpoint : `informe-primermovimiento.json`,
    //Configuración de columnas    
    t_columns : [
      { 
        name: 'codvehiculo',
        data: 'codvehiculo',
        title: "Id",
        width: "5%",
        className: "dt-center"
      },
      { 
        name: 'fecha',
        data: 'fecha',
        title: "Fecha",
        width: "8%"
      },
      { 
        name: 'movil',
        data: 'movil',
        title: "Móvil",
        width: "15%"
      },
      { 
        name: 'direccion',
        data: 'direccion',
        title: "Dirección",
        width: "25%",
        className: "dt-center"
      },
      { 
        name: 'puntocercano',
        data: 'puntocercano',
        title: "Punto Cercano",
        width: "15%",
        className: "dt-center"
      }
    ],
    // Columnas a exportar
    exp_columns : [0,1,2,3,4],
    // Columa que debe llevar al header
    htarget     : null,     
    // Columna con botones de acciones
    actionbtn   : null,   
    // Agrupa o no (Deja un header arriba)  
    agrupar     : false,
  } 

  constructor(
    private _rastreo:RastreoService,
    private activatedRoute:ActivatedRoute
  ) {
      //Trae la fecha inicial del día
      this.fechaInicial();
      //Trae la fecha con idioma español
      this.fechalang = this._rastreo.fechalang;
  }
  
  ngOnInit(){
  }

  ngAfterViewInit(){    
  }

  //Fecha Inicial
  fechaInicial(){ 
    let fecha = this._rastreo.DiaEntero();
    this.filtro.dia = fecha.desde;
    this.filtro.mes = fecha.hasta; 
  }

  getTiempo(input, $event){
    let fecha = this._rastreo.DiaHoraActual();
    if(input=='dia'){ this.filtro.dia = fecha; }
    if(input=='mes'){ this.filtro.mes = fecha; }
  }

  setFiltros(){
    this.tabla.filtros = {
      dia: this.filtro.dia,
      mes: this.filtro.mes
    };
  }

  //Seteamos los parámetros de la tabla y traemos el contenido
  getLista(){    
    //Agrega el filtro de fechas
    this.setFiltros();   
    //Trae los móviles
    this.tabla.setData(this.configTable);    
  }

}
