import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';


@Component({
    selector: 'app-acceso-sistema',
    templateUrl: '../templates/accesoSistema.component.html'
})
export class AccesoSistemaComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Acceso al Sistema`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `accesoSistema`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'fecha',
                data: 'fecha',
                title: "Fecha",
                width: "20%",
                className: "dt-center"
            },
            {
                name: 'hora',
                data: 'hora',
                title: "Hora",
                width: "20%"
            },
            {
                name: 'usuario',
                data: 'usuario',
                title: "Usuario",
                width: "20%"
            },
            {
                name: 'ingreso',
                data: 'ingreso',
                title: "Ingreso",
                width: "20%"
            },
            {
                name: 'ip',
                data: 'ip',
                title: "Dirección IP",
                width: "20%"
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Administración de Acceso al Sistema`);
    }

    ngOnInit() {
        this.obtenerAccesoSistema();
    }
    obtenerAccesoSistema() {
        this.tabla.setData(this.configTable);
    }
}
