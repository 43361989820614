
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import { Gtp } from '../../../../constants/gtp';
import { Constant } from '../../../../constants/constant';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CheckoutMovilItemService {

    public baseUrl: string = '';

    constructor(public http: Http,
        public gtp: Gtp,
        public constant: Constant) {
        this.baseUrl = environment.urlApiCheckoutMovil;
    }
}