//angular
import { Routes, RouterModule } from '@angular/router';
import { GruposComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const gruposRoutes: Routes = [
    {
        path: 'administracion/grupos',
        children: [
            {
                path: 'lista',
                component: GruposComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const gruposRouting = RouterModule.forChild(gruposRoutes);