//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { checkoutMovilRouting } from './checkoutMovil.routing';
import { TablaModule } from '../../shared/infotabla/infoTabla.module';
import { CheckoutMovilUsuarioComponent } from './usuario/components';
import { CheckoutMovilUsuarioService } from './usuario/services';
import { CheckoutMovilItemComponent } from './item/components';
import { CheckoutMovilItemService } from './item/services';
import { CheckoutMovilDocumentoComponent } from './documento/components';
import { CheckoutMovilHistorialComponent, CheckoutMovilHistorialDetalleComponent } from './historial/components';
import { SharedModule } from '../../shared/shared.module';
import { CheckoutMovilDocumentoService } from './documento/services';
import { CheckoutMovilItemPuntuacionTipoComponent, CheckoutMovilItemPuntuacionComponent } from './puntuacion/components';
import { CheckoutMovilItemPuntuacionService } from './puntuacion/services';
import { GMapModule, CalendarModule } from 'primeng/primeng';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TablaModule,
        checkoutMovilRouting,
        SharedModule,
        GMapModule,
        CalendarModule,
    ],
    declarations: [
        CheckoutMovilUsuarioComponent,
        CheckoutMovilItemComponent,
        CheckoutMovilDocumentoComponent,
        CheckoutMovilHistorialComponent,
        CheckoutMovilHistorialDetalleComponent,
        CheckoutMovilItemPuntuacionTipoComponent,
        CheckoutMovilItemPuntuacionComponent
    ],
    providers: [
        CheckoutMovilUsuarioService,
        CheckoutMovilItemService,
        CheckoutMovilDocumentoService,
        CheckoutMovilItemPuntuacionService
    ],
    exports: [
        CheckoutMovilUsuarioComponent,
        CheckoutMovilItemComponent,
        CheckoutMovilDocumentoComponent,
        CheckoutMovilHistorialComponent,
        CheckoutMovilHistorialDetalleComponent,
        CheckoutMovilItemPuntuacionTipoComponent,
        CheckoutMovilItemPuntuacionComponent
    ]
})

export class CheckoutMovilModule { }