//angular
import { Routes, RouterModule } from '@angular/router';
import { RecorridoComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';
//components

const recorridoRoutes: Routes = [
    { path: 'movil', children: [
        {
            path: 'recorrido/:id',
            component: RecorridoComponent,
            canActivate: [AuthGuard]
        }]
    }
];

export const recorridoRouting = RouterModule.forChild(recorridoRoutes);