//angular
import { Routes, RouterModule } from '@angular/router';
import { PerfilComponent } from './components';
import { AuthGuard } from '../../auth/auth.guard';

const perfilRoutes: Routes = [
    {
        path: 'perfil',
        children: [
            {
                path: 'ver',
                component: PerfilComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const perfilRouting = RouterModule.forChild(perfilRoutes);