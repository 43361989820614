//angular
import { Routes, RouterModule } from '@angular/router';
import { ParadasComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';
//components

const paradasRoutes: Routes = [
    { path: 'movil', children: [
        {
            path: 'paradas/:id',
            component: ParadasComponent,
            canActivate: [AuthGuard]
        }]
    }
];

export const paradasRouting = RouterModule.forChild(paradasRoutes);