import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from "@angular/http";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { environment } from '../../environments/environment';
import { HttpParams, HttpResponse } from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { verFlotaMovilModel } from '../rastreo/models/verflota-movil.model';

import { Gtp } from '../constants/gtp';

@Injectable()
export class MapaService {

  public baseUrl:any = environment.baseUrl;
	polylines:any;

	constructor(
			public http: HttpClient,
			private gtp: Gtp
	){}
	
	//Trae toda la flota con última ubicación
	getFlota():Observable<verFlotaMovilModel[]>{		
		let user = JSON.parse(this.gtp.getItemLocalStorage('currentUser'));	
		let url = `${this.baseUrl}listar/getvehiculos/${user.cod_cliente}`;
		return this.http.get<verFlotaMovilModel[]>(url);
  }
  
	getPolylines(){
		return this.http.get(`${this.baseUrl}/test-polyline.json`)
		//.map( res => res.json() )
  }
  
	getMarkers(){
		return this.http.get(`${this.baseUrl}/test-polyline.json`)
		//.map( res => res.json() )
  }  

}