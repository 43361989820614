//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MUbicacionComponent } from './m-ubicacion.component';
import { GoogleMapModule } from '../../shared/googlemap/googlemap.module';
import { GMapModule } from 'primeng/primeng';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        GoogleMapModule,
        GMapModule,
    ],
    declarations: [
        MUbicacionComponent
    ],
    providers: [
        
    ],
    exports: [
        MUbicacionComponent
    ],
    entryComponents: [
        MUbicacionComponent,
    ]
})

export class MUbicacionModule { }