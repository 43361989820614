
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Gtp } from '../../../constants/gtp';
import { environment } from '../../../../environments/environment';
import { Constant } from '../../../constants/constant';
import { Observable } from '../../../../../node_modules/rxjs/Observable';


@Injectable()
export class InicioService {

    public baseUrl: string = '';
    public baseUrl2: string = '';

    constructor(public http: Http,
        public gtp: Gtp,
        public constant: Constant) {
        this.baseUrl = environment.baseUrl;
        this.baseUrl2 = environment.urlApiCheckoutMovil;
    }
    //todos los vehículos del usuario
    obtenerTodosLosVehiculos(codCliente: any): Observable<any[]> {
        return this.http.get(`${this.baseUrl}mobile/listar/getvehiculos/${codCliente}`, this.constant.options)
            .map(response => response.json());
    }
    //todos los vehículos puntuados con sus puntuaciones de ítems correspondientes
    obtenerVehiculosPuntuados(codCliente: any): Observable<any[]> {
        return this.http.get(`${this.baseUrl2}cliente/${codCliente}/vehiculo/puntuado`, this.constant.options)
            .map(response => response.json());
    }
}