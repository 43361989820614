import { Gtp } from './constants/gtp';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';

import { MyInterceptor } from './services/interceptor.service';

//Modulos
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { InlineSVGModule } from 'ng-inline-svg';

//Rutas
import { AppRoutingModule } from './app.routing';

//PrimeNG
import { CalendarModule } from 'primeng/primeng';
import { GMapModule } from 'primeng/primeng';

// -- Pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
// -- Services
import { RastreoService } from './services/rastreo.service';  
import { VehiculosService } from './services/vehiculos.service';
import { MapaService } from './services/mapa.service';
import { SidebarService } from './rastreo/services/sidebar.service';

import { UserService } from './auth/user.service';
import { AuthGuard } from './auth/auth.guard';

import { HeaderComponent } from './shared/header/header.component';

//-- Controlador Panels
import { SidepanelsService } from './rastreo/services/sidepanels.service';
// -- Shared ( Archivos Compartidos )
import { MenuTopComponent } from './shared/menu-top/menu-top.component';
import { AlertmsgComponent } from './shared/alertmsg/alertmsg.component';
// import { SignalloaderComponent } from './shared/signalloader/signalloader.component';

//Mapa
import { MFlotaComponent } from './mapa/m-flota/m-flota.component';
// -- Panel / Sidebar
import { SMovilesComponent } from './rastreo/sidebar/s-moviles/s-moviles.component';
import { SMenuComponent } from './rastreo/sidebar/s-menu/s-menu.component';
// Control de FLota
import { TiempoContactoonComponent } from './rastreo/controlflota/tiempo-contactoon/tiempo-contactoon.component';
import { PrimerMovimientoComponent } from './rastreo/controlflota/primer-movimiento/primer-movimiento.component';
import { CtrlgeocercasComponent } from './rastreo/controlflota/ctrlgeocercas/ctrlgeocercas.component';
import { DistanciamovilesComponent } from './rastreo/controlflota/distanciamoviles/distanciamoviles.component';
import { MapIcon } from './shared/googlemap/map.icon';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { LoginModule } from './rastreo/login/login.module';
import { ToastrModule } from 'ngx-toastr';
import { Constant } from './constants/constant';
import { AlertService } from './comunes/alerta/services';
import { AlertComponent } from './comunes/alerta/components';
import { InicioModule } from './rastreo/inicio/inicio.module';
import { ControlFlotaModule } from './rastreo/controlflota/controlFlota.module';
import { GoogleMapModule } from './shared/googlemap/googlemap.module';
import { RecorridoModule } from './rastreo/moviles/recorrido/recorrido.module';
import { ParadasModule } from './rastreo/moviles/paradas/paradas.module';
import { TablaModule } from './shared/infotabla/infoTabla.module';
import { DragulaModule } from 'ng2-dragula';
import { InformeModule } from './rastreo/moviles/informe/informe.module';
import { MUbicacionModule } from './mapa/m-ubicacion/m-ubicacion.module';
import { VehiculosModule } from './rastreo/administracion/vehiculos/vehiculos.module';
import { SharedModule } from './shared/shared.module';
import { UsuariosModule } from './rastreo/administracion/usuarios/usuarios.module';
import { AccesoSistemaModule } from './rastreo/administracion/accesoSistema/accesoSistema.module';
import { InformesModule } from './rastreo/administracion/infromes/informe.module';
import { MotivoModule } from './rastreo/administracion/motivoNoEntrega/motivo.module';
import { SupervisoresModule } from './rastreo/administracion/supervisores/supervisores.module';
import { GruposModule } from './rastreo/administracion/grupos/grupos.module';
import { ChoferesModule } from './rastreo/administracion/choferes/choferes.module';
import { PerfilModule } from './rastreo/perfil/perfil.module';
import { GruposReferenciasModule } from './rastreo/herramientas/gruposReferencias/gruposReferencias.module';
import { PuntosModule } from './rastreo/herramientas/puntos/puntos.module';
import { CheckoutMovilModule } from './rastreo/checkoutMovil/checkoutMovil.module';

@NgModule({
  declarations: [
    AppComponent,
    SMovilesComponent,
    SMenuComponent,
    MenuTopComponent,
    AlertmsgComponent,
    //SignalloaderComponent,
    MFlotaComponent,
    TiempoContactoonComponent,
    PrimerMovimientoComponent,
    CtrlgeocercasComponent,
    DistanciamovilesComponent,
    SafeHtmlPipe,
    HeaderComponent,
    SidebarComponent,
    AlertComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CalendarModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(), 
    PaginationModule.forRoot(), 
    AlertModule.forRoot(),    
    HttpModule,
    HttpClientModule,
    InlineSVGModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    GMapModule,
    NgbModule.forRoot(),
    LoginModule,
    ToastrModule.forRoot(),
    InicioModule,
    ControlFlotaModule,
    GoogleMapModule,
    RecorridoModule,
    ParadasModule,
    TablaModule,
    DragulaModule.forRoot(),
    InformeModule,
    MUbicacionModule,
    VehiculosModule,
    SharedModule,
    UsuariosModule,
    AccesoSistemaModule,
    InformesModule,
    MotivoModule,
    SupervisoresModule,
    GruposModule,
    ChoferesModule,
    PerfilModule,
    GruposReferenciasModule,
    PuntosModule,
    CheckoutMovilModule
  ],
  providers: [
    Gtp,
    RastreoService,
    SidepanelsService,
    VehiculosService,
    MapaService,
    SidebarService,
    MapIcon,
    UserService,
    AuthGuard,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MyInterceptor,
        multi: true
    },
    Constant,
    AlertService
  ],
  entryComponents: [
    SidebarComponent
  ],
  exports: [
    //SignalloaderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
