/*
Clase Iconos para los Mapas
*/

declare var google: any;

export class MapIcon {

    // json de colores para los íconos.
    iconColor: any = {
        green: '#7cb342',
        red: '#d32f2f',
        bllue: '#2196f3'
    };

    // json para configurar tamaño del ícon
    iconSize: any = {
        rotate: 0,
        width: null,
        height: null
    }

    // json de los íconos en svg que se van a mostrar en el mapa con sus respectivos tamaños
    iconos: any = {
        auto: {
            width: 44,
            height: 88,
            img: `
                <path d="M46.1 28.4c-0.6-1.1-2.1-1.8-3.4-2.1 0.3-3.2 0.4-6.3 0.4-9.2 0-0.7 0-1.3 0-2 0-4.8-2.4-9.5-6.3-11.9 -3.7-2.4-8.1-3.3-13.7-3 -5.5-0.3-10 0.6-13.7 3C5.7 5.5 3.2 10.2 3.2 15c0 0.7 0 1.3 0 2 0 3 0 6.1 0.4 9.2 -1.3 0.4-2.8 1-3.4 2.1 -0.3 0.6-0.3 1.3-0.1 2 0.1 0.2 0.2 0.4 0.7 0.5 0.7 0.1 3-0.6 4.1-1.2 -0.8 6.5-1.2 13.2-1 19.3 -1.1 1.6-0.8 3.8 0.1 5.2l0 0.9c0.1 2.3 0.1 4.4 0.4 6.8 -0.8 1.4-1 3.9-1.1 5.8l0 0 0 0c0 0.8 0 1.7 0 2.5 0 3 0 6.1 0.5 9.3 0.6 3.4 2.7 6 6.4 8.1 4 2.3 8.9 2.5 12.8 2.5 0 0 0 0 0 0 3.8 0 8.7-0.3 12.8-2.5 3.7-2.1 5.8-4.7 6.4-8.1 0.5-3.1 0.5-6.3 0.5-9.3 0-0.8 0-1.7 0-2.5l0 0 0 0c-0.1-2-0.3-4.4-1.1-5.8 0.3-2.4 0.3-4.6 0.4-6.8l0-0.9c1-1.3 1.2-3.6 0.1-5.2 0.1-6.1-0.2-12.7-1-19.3 1.1 0.7 3.4 1.3 4.1 1.2 0.5 0 0.7-0.3 0.7-0.5C46.4 29.6 46.4 29 46.1 28.4zM45.6 30.1c-0.2 0.5-4-0.8-4.4-1.3 -0.1-0.2-0.5 0-0.6-0.1 0.8 6.4 1.3 13.6 1.1 20.4 1.1 1.4 0.8 3.6-0.1 4.8 -0.1 2.8-0.1 5.2-0.5 8 0.7 1.2 1 3.6 1.1 5.7 0 3.9 0.1 7.8-0.5 11.7 -0.6 3.3-2.7 5.8-6.1 7.7 -3.7 2.1-8.3 2.5-12.5 2.5 -4.2 0-8.8-0.4-12.5-2.5 -3.4-1.9-5.5-4.3-6.1-7.7C4 75.3 4.2 71.5 4.1 67.6c0.1-2.2 0.3-4.5 1.1-5.7 -0.3-2.8-0.4-5.2-0.5-8 -0.9-1.1-1.2-3.4-0.1-4.8 -0.2-6.8 0.3-14 1.1-20.4 -0.1 0.1-0.5-0.1-0.6 0.1 -0.4 0.6-4.2 1.8-4.4 1.3 -0.7-1.8 1.2-2.9 3.5-3.4 -0.5-4-0.4-7.9-0.4-11.7 0-4.7 2.5-9.2 6-11.4 4.2-2.7 8.9-3.2 13.4-2.9 4.4-0.3 9.2 0.2 13.4 2.9 3.5 2.3 6 6.7 6 11.4 0 3.8 0.1 7.7-0.4 11.7C44.4 27.2 46.3 28.3 45.6 30.1z"/>
                <path class="st0" d="M42.1 26.7c0.5-4 0.4-7.9 0.4-11.7 0-4.7-2.5-9.2-6-11.4 -4.2-2.7-8.9-3.2-13.4-2.9C18.8 0.4 14 0.9 9.8 3.6c-3.5 2.3-6 6.7-6 11.4 0 3.8-0.1 7.7 0.4 11.7 -2.3 0.5-4.2 1.6-3.5 3.4 0.2 0.5 4-0.8 4.4-1.3 0.1-0.2 0.5 0 0.6-0.1C4.9 35 4.4 42.2 4.6 49c-1.1 1.4-0.8 3.6 0.1 4.8 0.1 2.8 0.1 5.2 0.5 8 -0.7 1.2-1 3.6-1.1 5.7 0 3.9-0.1 7.8 0.5 11.7 0.6 3.3 2.7 5.8 6.1 7.7 3.7 2.1 8.3 2.5 12.5 2.5 4.2 0 8.8-0.4 12.5-2.5 3.4-1.9 5.5-4.3 6.1-7.7 0.6-3.9 0.4-7.8 0.5-11.7 -0.1-2.2-0.3-4.5-1.1-5.7 0.3-2.8 0.4-5.2 0.5-8 0.9-1.1 1.2-3.4 0.1-4.8 0.2-6.8-0.3-14-1.1-20.4 0.1 0.1 0.5-0.1 0.6 0.1 0.4 0.6 4.2 1.8 4.4 1.3C46.3 28.3 44.4 27.2 42.1 26.7z"/>
                <path d="M7 71C4.8 54.8 4.3 40.4 6.3 24.6 9 39.6 8.9 55.1 7 71z"/>
                <path d="M12.5 47.6l0.2-8.5c0-0.9 0.8-1.7 1.7-1.7 2.9-0.1 5.9-0.2 8.8-0.2 2.9-0.1 5.9 0.1 8.8 0.2 0.9 0 1.7 0.8 1.7 1.7l0.2 8.5c0 0.9-0.8 1.7-1.7 1.7 -6 0-11.9 0-17.9 0C13.2 49.3 12.4 48.6 12.5 47.6z"/>
                <path d="M40.1 24.6c-2.2 16.2-2.7 30.6-0.8 46.5C42.1 56.1 42 40.5 40.1 24.6z"/>
                <path d="M23.2 65.7c-4.7-0.2-9.3-0.5-12.9-1.5 0.1 4.5 0.1 9 0.2 13.6 4.1 2.6 8.4 3.5 12.7 3.5 4.4 0 8.6-0.9 12.7-3.5 0.1-4.5 0.1-9 0.2-13.6C32.4 65.2 27.9 65.5 23.2 65.7z"/>
                <path d="M23.2 14.4c-7.6 0.2-13.2 2.4-16.1 7.1 0.6 4.3 1.3 8.6 1.9 12.9 4.6-1.5 9.3-2 14.2-2 4.8 0 9.6 0.5 14.2 2 0.6-4.3 1.3-8.6 1.9-12.9C36.4 16.8 30.8 14.6 23.2 14.4z"/>
                <path d="M6.3 9C6.1 8.5 7.1 7.2 8.6 6c1.5-1.2 2.8-1.7 3-1.3 0.2 0.5-0.8 1.8-2.3 3C7.8 8.9 6.5 9.4 6.3 9L6.3 9z"/>
                <path d="M34.8 4.7c-0.2 0.5 0.8 1.8 2.3 3 1.5 1.2 2.8 1.7 3 1.3 0.2-0.5-0.8-1.8-2.3-3C36.3 4.8 35 4.3 34.8 4.7z"/>
            `
        },
        flecha: {
            width: 24,
            height: 24,
            img: `
                <path d="M23.677 18.52c.914 1.523-.183 3.472-1.967 3.472h-19.414c-1.784 0-2.881-1.949-1.967-3.472l9.709-16.18c.891-1.483 3.041-1.48 3.93 0l9.709 16.18z"/>
            `
        }
    }
    /**
     * Función para recuperar el ícono del auto.
     */
    auto() {
        //Tamaño Icono
        this.iconSize.width = this.iconos.auto.width;
        this.iconSize.height = this.iconos.auto.height;
        //Código del SVG
        let ico = this.iconos.auto.img;
        return ico;
    }
    /**
     * Función para recuperar el ícono de la flecha.
     */
    flecha() {
        //Tamaño Icono
        this.iconSize.width = this.iconos.flecha.width;
        this.iconSize.height = this.iconos.flecha.height;
        //Código del SVG
        let ico = this.iconos.flecha.img;
        return ico;
    }
    //Setea el Color del icono
    setColor(color) {
        let iconColor;
        if (color == 'activo') { iconColor = this.iconColor.green; }
        if (color == 'inactivo') { iconColor = this.iconColor.red; }
        return iconColor;
    }
    //Selecciona el icono
    setIcon(tipo) {
        let icon;
        if (tipo == 'auto') { icon = this.auto(); }
        if (tipo == 'flecha') { icon = this.flecha(); }
        return icon;
    }
    //Parsea y prepara el SVG
    parseIcon(rotate, tipo, color) {
        //Valores máximos de tamaño del ícono, se adapta proporcionalmente al espacio.
        //let icon = this.auto();
        let icon = this.setIcon(tipo);
        let w_ico = this.iconSize.width;
        let h_ico = this.iconSize.height;
        let size = (w_ico > h_ico) ? w_ico : h_ico;
        let iconColor = this.setColor(color);
        //Código SVG del ícono
        let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="` + size + `" height="` + size + `" viewBox="0 0 ` + w_ico + ` ` + h_ico + `" preserveAspectRatio="xMid YMid">
          <style type="text/css">
            .st0{ fill: `+ iconColor + `; }    
          </style>
          <g transform='rotate(`+ rotate + ` ` + w_ico / 2 + ` ` + h_ico / 2 + `)'>
            `+ icon + `
          </g>
        </svg>`;
        return 'data:image/svg+xml;utf-8;base64, ' + btoa(svg);
    }
    //Trae el Icono
    getIcon(rotate, tipo, color) {
        let icon = this.parseIcon(rotate, tipo, color);
        return {
            url: icon,
            offset: '0%',
            point: new google.maps.Point(this.iconSize.width / 2, this.iconSize.height / 2),
            anchor: new google.maps.Point(this.iconSize.width / 2, this.iconSize.height / 3.6),
            scaledSize: new google.maps.Size(this.iconSize.height / 2, this.iconSize.height / 2)
        };
    }
}