import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
 
@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(
        private router: Router
    ){}

    canActivate() {        
        // Redirecciona al login en caso que no esté logueado
        if(!localStorage.getItem('currentUser')){
            this.router.navigate(['/login']);
            return false;
        }
        //Si está logueado continua
        return true;
    }    
    
}