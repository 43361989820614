//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablaModule } from '../../../shared/infotabla/infoTabla.module';
import { GruposComponent } from './components';
import { gruposRouting } from './grupos.routing';
import { GMapModule, CalendarModule } from 'primeng/primeng';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TablaModule,
        gruposRouting,
        GMapModule,
        CalendarModule,
    ],
    declarations: [
        GruposComponent
    ],
    providers: [

    ],
    exports: [
        GruposComponent
    ]
})

export class GruposModule { }