import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { RastreoService } from '../../../services/rastreo.service';
import { SidepanelsService } from '../../services/sidepanels.service';
import { TablaComponent } from '../../../shared/infotabla/infotabla.component';
import { Subscription } from 'rxjs';

declare const $;

@Component({
  selector: 'app-tiempo-contactoon',
  templateUrl: './tiempo-contactoon.component.html',
  styleUrls: ['./tiempo-contactoon.component.scss']
})
export class TiempoContactoonComponent implements OnInit {

  mainClass:any;
  @ViewChild(TablaComponent) tabla:TablaComponent;

  subscription: Subscription;

  //Datos de Lenguaje del DateTime Picker
  fechalang:any;  
  //Valores Fecha Desde / Hasta
  filtro:any = {
    fdesde: '',
    fhasta: ''
  };

  tableData:any;
  configTable:any = {
    //Nombre del archivo a Exportar
    filename : "Tiempo Contacto On por Móviles",
    //Usuario Genero PDF
    user_g : "",
    //Totales top
    headerPdf : ``,
    //Link endPoint
    endpoint : `informe-tiempocontactoon.php`,
    //Configuración de columnas    
    t_columns : [
      { 
        name: 'codvehiculo',
        data: 'codvehiculo',
        title: "Id",
        width: "5%",
        className: "dt-center"
      },
      { 
        name: 'nombre',
        data: 'nombre',
        title: "Nombre",
        width: "20%"
      },
      { 
        name: 't_contacto_on',
        data: 't_contacto_on',
        title: "Tiempo Contacto On",
        width: "8%",
        className: "dt-center"
      }
    ],
    // Columnas a exportar
    exp_columns : [0,1,2],
    // Columa que debe llevar al header
    htarget     : null,     
    // Columna con botones de acciones
    actionbtn   : null,   
    // Agrupa o no (Deja un header arriba)  
    agrupar     : false
  }   

  constructor( 
    private _rastreo:RastreoService,
    private _panel:SidepanelsService,
    private activatedRoute:ActivatedRoute
  ) {
      //Setea la clase del div main      
      //Trae la fecha inicial del día
      this.fechaInicial();
      //Trae la fecha con idioma español
      this.fechalang = this._rastreo.fechalang;
      this.setMainClass();
  }
  
  ngOnInit(){
    
  }

  ngAfterViewInit(){    
  }

  setMainClass(){
    let panel = this._panel.getPanelInfo();
    this.subscription = this._panel.getMainClass().subscribe(resp => { this.mainClass = resp; });
    this._panel.setPanelSizes();
  }

  //Fecha Inicial
  fechaInicial(){ 
    let fecha = this._rastreo.DiaEntero();
    this.filtro.fdesde = fecha.desde;
    this.filtro.fhasta = fecha.hasta; 
  }

  getTiempo(input, $event){
    let fecha = this._rastreo.DiaHoraActual();
    if(input=='fdesde'){ this.filtro.fdesde = fecha; }
    if(input=='fhasta'){ this.filtro.fhasta = fecha; }
  }

  setFiltros(){
    this.tabla.filtros = {
      fdesde: this.filtro.fdesde,
      fhasta: this.filtro.fhasta
    };
  }

  //Seteamos los parámetros de la tabla y traemos el contenido
  getLista(){    
    //Agrega el filtro de fechas
    this.setFiltros();   
    //Trae los móviles
    this.tabla.setData(this.configTable);    
  }

}
