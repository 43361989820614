import { Component, OnInit } from '@angular/core';
import { RastreoService } from '../../../services/rastreo.service';
import { Subscription } from 'rxjs';

import { SidepanelsService } from '../../services/sidepanels.service';
import { AlertService } from '../../../comunes/alerta/services';
import { InicioService } from '../services';
import { Gtp } from '../../../constants/gtp';
import { Router, ActivatedRoute } from '@angular/router';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-inicio',
  templateUrl: '../templates/inicio.component.html',
  styleUrls: ['../templates/inicio.component.scss']
})
export class InicioComponent implements OnInit {

  panel: any;
  mainClass: any;
  subscription: Subscription;
  subs = new Subscription();
  list: any[] = [];
  vehiculosPuntuados: any = {};
  temp = [];
  codCliente: string;
  clasificacion: any = [];
  todosLosMoviles: any[];
  parados: any[];
  enMovimiento: any[];
  sinTransmitir: any[];
  cheadoConExito: any[];
  cheadoSinExito: any[];

  constructor(
    private _rastreo: RastreoService,
    private _panel: SidepanelsService,
    public alertService: AlertService,
    public inicioService: InicioService,
    public router: Router,
    public route: ActivatedRoute,
    public gtp: Gtp,
    public dragulaService: DragulaService) {
    this.subs.add(this.dragulaService.drop()
      .subscribe(({ name, el, target, source, sibling }) => {
        // ...
        console.log(name)
        console.log(el)
        console.log(target)
        console.log(source)
        console.log(sibling)
      })
    );
    this.setMainClass();
    this.gtp.setTituloCabecera('Dashboard');
  }

  setMainClass() {
    let panel = this._panel.getPanelInfo();
    this.subscription = this._panel.getMainClass().subscribe(resp => { this.mainClass = resp; });
    this._panel.setPanelSizes();
  }

  ngOnInit() {
    this.codCliente = this.gtp.obtenerUsuarioActual().cod_cliente;
    this.inicioService.obtenerVehiculosPuntuados(this.codCliente)
      .subscribe(
        data => {
          this.vehiculosPuntuados = data;
        },
        error => {
          this.alertService.error(JSON.stringify(error));
        });
    this.obtenerTodosLosVehiculos();
  }
  ngOnDestroy() {
    // destroy all the subscriptions at once
    this.subs.unsubscribe();
  }
  /**
   * Función para obtener la lista de todos los vehículos de un cliente, dado el código del cliente.
   */
  obtenerTodosLosVehiculos() {
    this.inicioService.obtenerTodosLosVehiculos(this.codCliente)
      .subscribe(
        data => {
          this.list = data;
          this.temp = [...data];
          this.clasificarVehiculos();
        },
        error => {
          this.alertService.error(JSON.stringify(error));
        });
  }
  /*
  Funcion para filtrar por código de vehículo la lista de vehículos.
  * @param event: evento de tipeo de caracter de filtro.
  * ************************************************************************************************/
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      var res;
      //Filtra por nombre
      var nombre = d.des_equipo_ratreo.toLowerCase().toString().indexOf(val) !== -1 || !val; if (nombre) { res = nombre; }
      //Filtra por Cod Veh
      var cod_vehiculo = d.cod_equipo_rastreo.toString().indexOf(val) !== -1 || !val; if (cod_vehiculo) { res = cod_vehiculo; }
      //Filtra por Fecha
      var fecha = d.ult_fecha_hora.toLowerCase().toString().indexOf(val) !== -1 || !val; if (fecha) { res = fecha; }
      return res;
    });
    this.list = temp;
  }

  /**
   * Función para clasificar los vehículos según el estado en que se encuentran, En Movimiento | Parado | Sin Transmitir
   */
  clasificarVehiculos() {
    this.todosLosMoviles = this.list;
    this.parados = [];
    this.enMovimiento = [];
    this.sinTransmitir = [];
    this.cheadoConExito = [];
    this.cheadoSinExito = [];
    let enMov = 0;
    let parado = 0;
    let sinTransmitir = 0;
    let tiempo = 0
    let conExito = 0;
    let sinExito = 0;
    this.list.forEach(element => {
      if (element.ult_velocidad > 0) {
        enMov = enMov + 1;
        element.icon = 'icon-success';
        this.enMovimiento.push(element);
      } else if (element.ult_velocidad == 0) {
        parado = parado + 1;
        element.icon = 'icon-danger';
        this.parados.push(element);
      }
      tiempo = this._rastreo.ObtenerTiempo(element.ult_fecha_hora);
      if (tiempo > 30) {
        sinTransmitir = sinTransmitir + 1;
        element.icon = 'icon-warning';
        this.sinTransmitir.push(element);
      }
      let aux = this.vehiculosPuntuados[element.cod_vehiculo]
      element.image = 'business_bulb-63';
      element.color = 'color-warning';
      element.check_movil_tituto = 'Móvil sin Chequeo';
      //para controlar que si al menos un ítem no cumple con la puntuación mínima, entonces el ckeckout no fue exitoso, caso contrario si todos cumplen sí.
      if (aux) {
        let exitoso = true;
        aux.forEach(item => {
          if (parseInt(item.item_puntuacion_valor) < parseInt(item.item_puntaje_minimo)) {
            exitoso = false
          }
        })
        element.fecha_actualizacion = aux[0].fecha_actualizacion;
        element.usuario_modificador = aux[0].usuario_modificador_username;
        element.chofer = `${aux[0].nombre_chofer} ${aux[0].apellido_chofer}`;
        console.log(exitoso);
        if (exitoso) {
          conExito = conExito + 1;
          this.cheadoConExito.push(element);
          element.image = 'ui-1_check';
          element.color = 'color-success';
          element.check_movil_tituto = 'Móvil con Chequeo Exitoso';  
        } else {
          sinExito = sinExito + 1;
          this.cheadoSinExito.push(element);
          element.image = 'ui-1_simple-remove';
          element.color = 'color-danger';
          element.check_movil_tituto = 'Móvil sin Chequeo Exitoso'
        }
      }
    });
    let r = { cantidad: this.list.length, descripcion: 'Todos los Móviles', icon: '', image: 'fa-car' };
    this.clasificacion.push(r);
    r = { cantidad: enMov, descripcion: 'Móviles en Movimiento', icon: 'icon-success', image: 'fa-car' };
    this.clasificacion.push(r);
    r = { cantidad: parado, descripcion: 'Móviles Parados', icon: 'icon-danger', image: 'fa-car' };
    this.clasificacion.push(r);
    r = { cantidad: sinTransmitir, descripcion: 'Móviles sin Transmitir', icon: 'icon-warning', image: 'fa-car' };
    this.clasificacion.push(r);

    r = { cantidad: conExito, descripcion: 'Móviles con Chequeo Exitoso', icon: 'icon-success', image: 'fa-check' };
    this.clasificacion.push(r);
    r = { cantidad: sinExito, descripcion: 'Móviles sin Chequeo Exitoso', icon: 'icon-danger', image: 'fa-times' };
    this.clasificacion.push(r);
  }

  /**
   * Función para filtrar los vehículos por su estado, según la descripción del estado recibido como parámetro, Todos | En Movimiento | Parados | Sin Transmitir
   * @param descripcion descripción del estado del vehículo a ser filtrado
   */
  filtrarVehiculosPorEstado(descripcion) {
    switch (descripcion) {
      case 'Todos los Móviles':
        this.list = this.todosLosMoviles;
        break;
      case 'Móviles en Movimiento':
        this.list = this.enMovimiento;
        break;
      case 'Móviles Parados':
        this.list = this.parados;
        break;
      case 'Móviles sin Transmitir':
        this.list = this.sinTransmitir;
        break;
      case 'Móviles con Chequeo Exitoso':
        this.list = this.cheadoConExito;
        break;
      case 'Móviles sin Chequeo Exitoso':
        this.list = this.cheadoSinExito;
        break;
    }
    this.temp = [...this.list];
  }

  redireccionar(ruta, objeto) {
    this.gtp.setItemLocalStorage('vehiculo', objeto);
    this.router.navigate([ruta, objeto.cod_vehiculo]);
  }
}