import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';
import { AlertService } from '../../../../comunes/alerta/services';
import { Constant } from '../../../../constants/constant';

@Component({
    selector: 'app-checkout-movil-item-puntuacion',
    templateUrl: '../templates/checkoutMovilItemPuntuacion.component.html'
})
export class CheckoutMovilItemPuntuacionComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Puntuaciones para ítems de Vehículos en Aplicación Checkout Movil `,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `vehiculo/item/puntuacion`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'nombre',
                data: 'nombre',
                title: "Nombre",
                width: "30%",
                className: "dt-center"
            },
            {
                name: 'descripcion',
                data: 'descripcion',
                title: "Descripción",
                width: "30%"
            },
            {
                name: 'item_puntuacion',
                title: "Valores",
                width: "30%",
                render: function (data: any, type: any, row: any, meta) {
                    let html = '[';
                    row.puntuaciones.forEach((p, index) => {
                        html = `${html} <span>${p.valor} ${index != row.puntuaciones.length - 1 ? ',' : ''}</span>`;
                    });
                    html = html + ']'
                    return html;
                }
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "10%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="Eliminar" data-toggle="modal" data-target="#eliminarUsuario${row.id}">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </button>
                    <div class="modal fade modal-mini modal-primary" id="eliminarUsuario${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center p-15">
                                    <div class="modal-profile">
                                        <i class="fas fa-question"></i>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <p>¿Está seguro que desea eliminar el registro?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-link btn-neutral" data-dismiss="modal">Cancelar</button>
                                    <button type="button" class="btn btn-link btn-neutral" (click)="eliminarUsuarioMovil(${row.id})">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false,
        filtro: {}
    }
    constructor(public gtp: Gtp,
        public constant: Constant,
        public alertService: AlertService) {
        this.gtp.setTituloCabecera(`Puntuación de Ítems de Checkout Movil`);
        this.configTable.filtro.cliente_codigo = this.gtp.obtenerUsuarioActual().cod_cliente;
    }

    ngOnInit() {
        // se obtiene la lista de usuarios móviles de la app de checkout de móviles.
        this.obtenerCheckoutMovilItemPuntuacion(1);
    }

    obtenerCheckoutMovilItemPuntuacion(pagina) {
        this.configTable.filtro.pagina = pagina;
        this.tabla.setData(this.configTable);
    }
}
