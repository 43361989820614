import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signalloader',
  templateUrl: './signalloader.component.html',
  styleUrls: ['./signalloader.component.scss']
})
export class SignalloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
