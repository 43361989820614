import { Component, OnInit } from '@angular/core';
import { RastreoService } from '../../../services/rastreo.service';
import { AlertService } from '../../../comunes/alerta/services';
import { Gtp } from '../../../constants/gtp';

@Component({
    selector: 'app-perfil',
    templateUrl: '../templates/perfil.component.html'
})

export class PerfilComponent implements OnInit {

    codCliente: string;

    constructor(
        public _rastreo: RastreoService,
        public alertService: AlertService,
        public gtp: Gtp) {
        this.gtp.setTituloCabecera('Mi Perfil')
    }

    ngOnInit() {
        this.codCliente = this.gtp.obtenerUsuarioActual().cod_cliente;
    }

}
