import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';
import { CheckoutMovilUsuarioService } from '../services';
import { AlertService } from '../../../../comunes/alerta/services';
import { Constant } from '../../../../constants/constant';
import { InicioService } from '../../../inicio/services';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-checkout-movil-usuario',
    templateUrl: '../templates/checkoutMovilUsuario.component.html'
})
export class CheckoutMovilUsuarioComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;
    usuarioRolList: any[];
    choferesList: any[];
    codCliente: any;
    vehiculosList: any[];
    public form: FormGroup;
    model: any = {};
    admin: boolean = true;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Usuarios de Aplicación Checkout Movil `,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `usuario/movil`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'username',
                data: 'username',
                title: "Usuario",
                width: "20%",
                className: "dt-center"
            },
            {
                name: 'usuario_movil_rol',
                data: 'usuario_movil_rol',
                title: "Perfil",
                width: "20%"
            },
            {
                name: 'activo',
                data: 'activo',
                title: "Activo",
                width: "10%",
                render: function (data: any, type: any, row: any, meta) {
                    let html;
                    if (row.activo) {
                        html = `<span>Sí</span>`;
                    } else {
                        html = `<span>No</span>`
                    }
                    return html;
                }
            },
            {
                name: 'chofer',
                data: 'chofer',
                title: "Chofer Asignado",
                width: "20%",
                render: function (data: any, type: any, row: any, meta) {
                    let html = `<span>${row.nom_chofer} ${row.ape_chofer}</span>`;
                    return html;
                }
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "30%",
                render: function (data: any, type: any, row: any, meta) {
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="Eliminar" (click)="eliminarUsuarioMovil(${row.id})">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </button>
                    <div class="modal fade modal-mini modal-primary" id="eliminarUsuario${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center p-15">
                                    <div class="modal-profile">
                                        <i class="fas fa-question"></i>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <p>¿Está seguro que desea eliminar el registro?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-link btn-neutral" data-dismiss="modal">Cancelar</button>
                                    <button type="button" class="btn btn-link btn-neutral" (click)="eliminarUsuarioMovil(${row.id})">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false,
        filtro: {}
    }
    constructor(public gtp: Gtp,
        public constant: Constant,
        public checkoutMovilUsuarioService: CheckoutMovilUsuarioService,
        public vehiculoService: InicioService,
        public formBuilder: FormBuilder,
        public alertService: AlertService) {
        this.gtp.setTituloCabecera(`Usuarios de Checkout Movil`);
        this.codCliente = this.gtp.obtenerUsuarioActual().cod_cliente;
        this.configTable.filtro.cliente_codigo = this.codCliente
    }

    ngOnInit() {
        //validaciones para el forumulario de usuario.
        let usuario = new FormControl('', Validators.required);
        let chofer_id = new FormControl('', Validators.required);
        let rol_id = new FormControl('', Validators.required);
        let vehiculo_id = new FormControl('', Validators.required);
        let clave = new FormControl('', Validators.required);
        let confirmacion_clave = new FormControl('', Validators.required);

        this.form = this.formBuilder.group({
            usuario: usuario,
            chofer_id: chofer_id,
            vehiculo_id: vehiculo_id,
            rol_id: rol_id,
            clave: clave,
            confirmacion_clave: confirmacion_clave
        });
        // se obtiene la lista de roles de los usuarios móviles de la app de checkout de móviles.
        this.checkoutMovilUsuarioService.getRolesUsuarioMovil()
            .subscribe(
                data => {
                    this.usuarioRolList = data;
                },
                error => {
                    this.alertService.error(JSON.stringify(error));
                });
        // se obtiene la lista de choferes del cliente.
        this.checkoutMovilUsuarioService.getChoferes(this.codCliente)
            .subscribe(
                data => {
                    this.choferesList = data;
                },
                error => {
                    this.alertService.error(JSON.stringify(error));
                });
        // se obtiene la lista de vehículos del cliente.
        this.vehiculoService.obtenerTodosLosVehiculos(this.codCliente)
            .subscribe(
                data => {
                    this.vehiculosList = data;
                },
                error => {
                    this.alertService.error(JSON.stringify(error));
                });
        // se obtiene la lista de usuarios móviles de la app de checkout de móviles.
        this.obtenerCheckoutMovilUsuario(1);
    }

    obtenerCheckoutMovilUsuario(pagina) {
        this.configTable.filtro.pagina = pagina;
        this.tabla.setData(this.configTable);
    }

    /**
     * Función para eliminar un usuario de la aplicación móvil de chekout móvil, según su id.
     * @param usuarioId identificador del usuario a eliminar
     */
    eliminarUsuarioMovil(usuarioId) {
        console.log('???????.......')
        console.log(usuarioId)
        // this.checkoutMovilUsuarioService.deleteRolesUsuarioMovil(usuarioId)
        //     .subscribe(
        //         data => {
        //             this.obtenerCheckoutMovilUsuario(1)
        //             this.alertService.success(this.constant.MSG_DELETE_SUCCESS, this.constant.TITLE_DELETE_SUCCESS);
        //         },
        //         error => {
        //             this.alertService.error(JSON.stringify(error));
        //         });
    }

    /***************************************************************
     * Función que verifica el perfil para habilitar el tipo de selección de vehículos.
     ***************************************************************/
    onChangeRol(id: number) {
        this.model.vehiculo_id = null;
        this.usuarioRolList.forEach(element => {
            if (element.id == id) {
                if (element.nombre == 'Administrador') {
                    this.admin = true;
                } else {
                    this.admin = false;
                }
            }
        })
    }
    guardarUsuario() {
        this.model.cliente_codigo = this.codCliente;
        this.model.activo = true;
        console.log(this.model);
        // se guarda el usuario en la base de datos.
        this.checkoutMovilUsuarioService.guardarUsuario(this.model)
            .subscribe(
                data => {
                    this.model = {};
                    this.alertService.success(this.constant.MSG_SUCCESS, this.constant.TITLE_SUCCESS);
                    this.obtenerCheckoutMovilUsuario(1);
                },
                error => {
                    this.alertService.error(JSON.stringify(error));
                });
    }
}
