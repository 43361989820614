
import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Gtp } from '../../../constants/gtp';
import { environment } from '../../../../environments/environment';
import { Constant } from '../../../constants/constant';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';



@Injectable()
export class AuthenticationService {

    public baseUrl: string = '';
    //public baseUrl:any = 'https://gtpmovil.com/rastreo/v1/';
    user: any = {};

    constructor(public http: Http,
        public gtp: Gtp,
        public route: ActivatedRoute,
        public router: Router,
        public constant: Constant) {
        this.baseUrl = environment.baseUrl;
    }
    //Login del usuario
    login(model: any) {
        let body = {
            name: model.username,
            clave: model.password
        }
        return this.http.post(`${this.baseUrl}auth/login`, body, this.constant.options)
            .map((response: Response) => {
                // login exitoso.
                this.user = response.json();
                if (this.user && this.user.token) {
                    this.constant.setHeaders(this.user.token);
                    //se guarda en sesion la respuesta del servicio.
                    this.gtp.setItemLocalStorage('currentUser', {
                        username: model.username,
                        token: this.user.token,
                        cod_cliente: this.user.cod_cliente
                    });

                }
                return this.user;
            });
    }
    //cerrar sesión del usuario
    logout(): void {
        // clear token remove user from local storage to log user out
        this.gtp.removeItemLocalStorage('currentUser');
        this.router.navigate(['rastreo/login']);
    }
    isAuthenticated() {
        return this.gtp.getItemLocalStorage('currentUser') != null;
    }
}