import { Injectable } from '@angular/core';
import { Http, Response} from "@angular/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../../../environments/environment';
import { distRecorridaVeh } from '../../../models/vehiculo-distrecorrida.model';


@Injectable()
export class DistanciaRecorridaService {

  public baseUrl:any = environment.baseUrl;
	lista_moviles:any;
	
	constructor(
		private http: Http
	){}

  //Trae listado de Móviles
	fetchDistRecorrida():Observable<distRecorridaVeh[]>{
		return this.http.get(`${this.baseUrl}/informe-distanciarecorrida.json`)
		.map( (res: Response) => res.json() )		
	}

}
