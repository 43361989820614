import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';


@Component({
    selector: 'app-usuarios',
    templateUrl: '../templates/usuarios.component.html',
    styleUrls: ['../templates/usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Usuarios`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `usuarios`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'usuario',
                data: 'usuario',
                title: "Usuario",
                width: "25%",
                className: "dt-center"
            },
            {
                name: 'perfil',
                data: 'perfil',
                title: "Perfil",
                width: "25%"
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "50%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="Editar" data-toggle="modal" data-target="#editarUsuario${row.id}">
                        <i class="now-ui-icons ui-2_settings-90"></i>
                    </button>
                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="Eliminar" data-toggle="modal" data-target="#eliminarUsuario${row.id}">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </button>
                    <div class="modal fade modal-mini modal-primary" id="eliminarUsuario${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center p-15">
                                    <div class="modal-profile">
                                        <i class="fas fa-question"></i>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <p>¿Está seguro que desea eliminar el registro?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-link btn-neutral" data-dismiss="modal">Cancelar</button>
                                    <button type="button" class="btn btn-link btn-neutral">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="editarUsuario${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title">Editar Usuario</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group has-label text-left">
                                        <label>
                                            Usuario
                                        </label>
                                        <input class="form-control bl-1" name="usuario" value="${row.usuario}" type="text" required="true" />
                                    </div>
                                    <div class="form-group has-label pos-left-0 text-left">
                                        <label>
                                            Perfil
                                        </label>
                                        <select class="selectpicker" data-size="7" data-style="btn btn-primary btn-round" value="1" title="Seleccione una opción">
                                            <option disabled selected>Seleccione una opción</option>
                                            <option value="1">Administrador</option>
                                            <option value="2">Liminatado</option>
                                            <option value="3">Operario</option>
                                        </select>
                                    </div>
                                    <div class="form-group has-label text-left">
                                        <label>
                                            Contraseña
                                        </label>
                                        <input class="form-control bl-1" name="clave" type="password" required="true" />
                                    </div>
                                    <div class="form-group has-label text-left">
                                        <label>
                                            Confirmación de Contraseña
                                        </label>
                                        <input class="form-control bl-1" name="confirmacionClave" type="password" required="true" />
                                    </div>
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-info btn-round" data-dismiss="modal">Agregar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Administración de Usuarios`);
    }

    ngOnInit() {
        this.obtenerUsuarios();
    }


    obtenerUsuarios() {
        this.tabla.setData(this.configTable);
    }
    showModal(data) {
        let map = data.split(";");
        let obj = JSON.parse(localStorage.getItem(map[0]));
        console.log(obj);
        if (map[1] == 'editar') {

        }
        if (map[1] == 'eliminar') {

        }
    }
}
