import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';


@Component({
    selector: 'app-importar-puntos-xls-roadshow',
    templateUrl: '../templates/importarPuntosXLSRoadshow.component.html'
})
export class ImportarPuntosXLSRoadshowComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Importar Puntos XLS Roadshow`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `importarPuntosXLSRoadshow`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'tamanho',
                data: 'tamanho',
                title: "Tamaño",
                width: "25%",
                className: "dt-center"
            },
            {
                name: 'nombre',
                data: 'nombre',
                title: "Nombre",
                width: "25%"
            },
            {
                name: 'convertir',
                orderable: false,
                title: "Convertir a",
                width: "25%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button class="btn buttons-html5 color-dark-blue btn-accion btn-round">
                        <i class="now-ui-icons location_map-big"></i> 
                        <span class="label-action">Mis Rutas</span>
                    </button>`;
                    return html;
                }
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "25%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="Descargar">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                    </button>
                    <button type="button" rel="tooltip" class="btn btn-danger btn-icon btn-sm " data-original-title="Eliminar" data-toggle="modal" data-target="#eliminarRegistro${row.id}">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </button>
                    <div class="modal fade modal-mini modal-primary" id="eliminarRegistro${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center p-15">
                                    <div class="modal-profile">
                                        <i class="fas fa-question"></i>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <p>¿Está seguro que desea eliminar el registro?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-link btn-neutral" data-dismiss="modal">Cancelar</button>
                                    <button type="button" class="btn btn-link btn-neutral">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Importar Puntos Roadshow`);
    }

    ngOnInit() {
        this.obtenerArchivosCargados();
    }


    obtenerArchivosCargados() {
        this.tabla.setData(this.configTable);
    }
}
