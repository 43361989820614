import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';


@Component({
    selector: 'app-informes',
    templateUrl: '../templates/informes.component.html'
})
export class InformesComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Informe Técnico`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `informes`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'fecha',
                data: 'fecha',
                title: "Fecha de Creación",
                width: "10%",
                className: "dt-center"
            },
            {
                name: 'lugar',
                data: 'lugar',
                title: "Lugar",
                width: "10%",
                className: "dt-center"
            },
            {
                name: 'tecnico',
                data: 'tecnico',
                title: "Técnico Responsable",
                width: "15%"
            },
            {
                name: 'tipo',
                data: 'tipo',
                title: "Tipo",
                width: "5%"
            },
            {
                name: 'equipo',
                data: 'equipo',
                title: "Descripción de Equipo",
                width: "15%"
            },
            {
                name: 'servicio',
                data: 'servicio',
                title: "Trabajos Realizados",
                width: "15%"
            },
            {
                name: 'recomendacion',
                data: 'recomendacion',
                title: "Recomendación Técnica",
                width: "15%"
            },
            {
                name: 'costo',
                data: 'costo',
                title: "Costo",
                width: "15%"
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Administración de Informes`);
    }

    ngOnInit() {
        this.obtenerInformes();
    }
    obtenerInformes() {
        this.tabla.setData(this.configTable);
    }
}
