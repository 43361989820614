//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablaModule } from '../../../shared/infotabla/infoTabla.module';
import { UsuariosComponent, UsuariosRoadshowComponent } from './components';
import { usuariosRouting } from './usuarios.routing';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TablaModule,
        usuariosRouting
    ],
    declarations: [
        UsuariosComponent,
        UsuariosRoadshowComponent
    ],
    providers: [

    ],
    exports: [
        UsuariosComponent,
        UsuariosRoadshowComponent
    ]
})

export class UsuariosModule { }