//angular
import { Routes, RouterModule } from '@angular/router';
import { InformesComponent } from './components';
import { AuthGuard } from '../../../auth/auth.guard';

const informesRoutes: Routes = [
    {
        path: 'administracion/informes',
        children: [
            {
                path: 'lista',
                component: InformesComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
];

export const informesRouting = RouterModule.forChild(informesRoutes);