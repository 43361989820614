//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//services
import { InicioService } from './services';
//components
import { InicioComponent } from './components';
//routers
import { inicioRouting } from './inicio.routing';
import { DragulaModule } from 'ng2-dragula';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        inicioRouting,
        DragulaModule
    ],
    declarations: [
        InicioComponent
    ],
    providers: [
        InicioService
    ],
    exports: [
        InicioComponent
    ]
})

export class InicioModule { }