import { Component, OnInit } from '@angular/core';

//Servicios
import { RastreoService } from '../../services/rastreo.service';

@Component({
  selector: 'menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss']
})
export class MenuTopComponent implements OnInit {

  menuTop:any;
  
  constructor(
    private _rastreo:RastreoService,
  ) {
    this.getMenuHeader();
  }

  ngOnInit() {
  }

  getMenuHeader(){
    this._rastreo.fetchMenuTop().subscribe(
      res=> {
        this.menuTop = res;
      },
      //Error
      err => { 
        //console.log(err); 
      },
      //Terminó de cargar
      () => {  }
    );    
  }

}
