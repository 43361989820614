//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglemapComponent } from './googlemap.component';
import { AgmCoreModule } from '@agm/core'; //Google Map
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB8ZhqrPmYu8YMSrM4Oh8BokU2NNw0tU9U'
          }),
        AgmJsMarkerClustererModule
    ],
    declarations: [
GooglemapComponent
    ],
    providers: [
        
    ],
    exports: [
        GooglemapComponent
    ]
})

export class GoogleMapModule { }