import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { RastreoService } from '../../../../services/rastreo.service';
import { Geocerca } from '../../../models/geocerca';
declare const $;

@Component({
  selector: 'app-vehiculo-personalizar',
  templateUrl: '../templates/vehiculo-personalizar.component.html',
  styleUrls: ['../templates/vehiculo-personalizar.component.scss']
})
export class VehiculoPersonalizarComponent implements OnInit {

  @ViewChild(TablaComponent) tabla: TablaComponent;

  openTabs: any = false;
  showFirst: any = true;
  
  correos: any = [
    'lickybuay@gmail.com',
    'lickybuay@gmail.com',
    'lickybuay@gmail.com',
    'lickybuay@gmail.com',
    'lickybuay@gmail.com',
    'lickybuay@gmail.com',
    'lickybuay@gmail.com'
  ]
  geocercaList = [
    {id: 1, nombre: 'Taller Vidal', entrada: false, salida: false, maxParada: false, valorMaximaParada: 0, maxVelocidad: false, valorMaxVelocidad: 0, activar: false}
  ]

  fechalang: any;
  hhasta;
  hdesde;

  configTable: any = {
    //Nombre del archivo a Exportar
    filename: `Personal Authorizado`,
    //Usuario Genero PDF
    user_g: "",
    //Totales top
    headerPdf: ``,
    //Link endPoint
    //endpoint: `informe/${this.codVehiculo}`,
    endpoint: `personal`,
    //Configuración de columnas    
    t_columns: [
      {
        name: 'nombre',
        data: 'nombre',
        title: "Nombre",
        width: "10%",
        className: "dt-center"
      },
      {
        name: 'apellido',
        data: 'apellido',
        title: "Apellido",
        width: "10%"
      },
      {
        name: 'cedula',
        data: 'cedula',
        title: "Cédula",
        width: "10%",
        className: "dt-center"
      },
      {
        name: 'email',
        data: 'email',
        title: "Email",
        width: "10%",
        className: "dt-center"
      },
      {
        name: 'telefono',
        data: 'telefono',
        title: "Teléfono",
        width: "10%",
        className: "dt-center"
      },
      {
        name: 'celular1',
        data: 'celular1',
        title: "Celular 1",
        width: "10%",
        className: "dt-center"
      },
      {
        name: 'celular2',
        data: 'celular2',
        title: "Celular 2",
        width: "10%",
        className: "dt-center"
      },
      {
        name: 'obtener_informacion',
        data: 'obtener_informacion',
        title: "Puede Obtener Información",
        width: "10%",
        className: "dt-center"
      },
      {
        name: 'contactar',
        data: 'contactar',
        title: "Contactar en caso Necesario",
        width: "15%",
        className: "dt-center"
      },
      {
        name: 'check',
        orderable: false,
        title: "Chequear",
        width: "5%",
        render: function (data: any, type: any, row: any, meta) {
          var html = `
              <div class="form-check">
              <label class="form-check-label">
                  <input class="form-check-input" type="checkbox">
                  <span class="form-check-sign"></span>
              </label>
          </div>`;
          return html;
        }
      }
    ],
    // Columnas a exportar
    exp_columns: [0, 1, 2, 3, 4],
    // Columa que debe llevar al header
    htarget: null,
    // Columna con botones de acciones
    actionbtn: null,
    // Agrupa o no (Deja un header arriba)  
    agrupar: false
  }
  constructor(public _rastreo: RastreoService) {
    //Trae la fecha con idioma español
    this.fechalang = this._rastreo.fechalang;
  }

  ngOnInit() {
    this.obtenerPersonalAuthorizado();
  }

  /**
 * Función para guardar en una lista las imagenes cargadas.
 * @param event evento que contiene la información de la imagen cargada
 */
  getImage(event): void {
    console.log('imagen cargada')
  }

  obtenerPersonalAuthorizado() {
    this.setFiltros();
    this.tabla.setData(this.configTable);
  }

  /**
 * Funcion para setear los valores de los filtros en la tabla para poderlos filtrar por eso datos.
 */
  setFiltros() {
    console.log(this.tabla)
    this.tabla.filtros = {
      fdesde: new Date()
    };
  }

  /***************************************************************
 * Función que agregar una geocerca al listado de geocercas y mostrar en la tabla
 * @param id: id de la geocerca a agregar
 ***************************************************************/
  agregarGeocerca(id: number) {
    let geocerca = new Geocerca();
    geocerca.id = 100000;
    geocerca.nombre = "Fábrica Cervepar";
    this.geocercaList.push(geocerca);
  }
  eliminarGeocerca(id: number) {
    
  }
}
