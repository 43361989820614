//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapModule } from '../../../shared/googlemap/googlemap.module';
import { RecorridoComponent } from './components';
import { recorridoRouting } from './recorrido.routing';
import { RecorridoService } from './services';
import { CalendarModule, GMapModule } from 'primeng/primeng';
import { MUbicacionModule } from '../../../mapa/m-ubicacion/m-ubicacion.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        recorridoRouting,
        GoogleMapModule,
        GMapModule,
        CalendarModule,
        MUbicacionModule
    ],
    declarations: [
        RecorridoComponent
    ],
    providers: [
        RecorridoService
    ],
    exports: [
        RecorridoComponent
    ]
})

export class RecorridoModule { }