import { Component, OnInit, ViewChild } from '@angular/core';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { Gtp } from '../../../../constants/gtp';

@Component({
    selector: 'app-motivo',
    templateUrl: '../templates/motivo.component.html'
})
export class MotivoComponent implements OnInit {

    @ViewChild(TablaComponent) tabla: TablaComponent;

    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Motivo de No Entrega`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        endpoint: `motivo`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'nombre',
                data: 'nombre',
                title: "Nombre",
                width: "25%",
                className: "dt-center"
            },
            {
                name: 'estado',
                title: "Estado",
                width: "25%",
                render: function (data: any, type: any, row: any, meta) {
                    let html;
                    if (row.estado) {
                        html = `<span>Activo</span>`;
                    }else {
                        html = `<span>Inactivo</span>`
                    }
                    return html;
                }
            },
            {
                name: 'opciones',
                orderable: false,
                title: "Opciones",
                width: "50%",
                render: function (data: any, type: any, row: any, meta) {
                    console.log(row)
                    localStorage.setItem(row.id, JSON.stringify(row));
                    var html = `
                    <button type="button" rel="tooltip" class="btn btn-info btn-icon btn-sm " data-original-title="Editar" data-toggle="modal" data-target="#editarMotivo${row.id}">
                        <i class="now-ui-icons ui-2_settings-90"></i>
                    </button>
                    <div class="modal fade" id="editarMotivo${row.id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title">Editar Movito</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group has-label text-left">
                                        <label>
                                            Nombre
                                        </label>
                                        <input class="form-control bl-1" name="nombre" value="${row.nombre}" type="text" required="true" />
                                    </div>
                                    <div class="form-group has-label pos-left-0 text-left">
                                        <div class="form-check not-padding">
                                            <label class="form-check-label">
                                                    Estado
                                                <input class="form-check-input" checked="${row.estado}" value="${row.estado}" [(ngModel)]="${row.estado}" type="checkbox">
                                                <span class="form-check-sign"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="btn btn-info btn-round" data-dismiss="modal">Agregar</button>
                                </div>
                            </div>
                        </div>
                    </div>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }
    constructor(public gtp: Gtp) {
        this.gtp.setTituloCabecera(`Administración de Motivos`);
    }
    ngOnInit() {
        this.obtenerMotivo();
    }
    obtenerMotivo() {
        this.tabla.setData(this.configTable);
    }
}
