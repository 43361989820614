import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { RastreoService } from '../../../services/rastreo.service';
import { TablaComponent } from '../../../shared/infotabla/infotabla.component';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

//Mapa Google Pop
import { MUbicacionComponent } from '../../../mapa/m-ubicacion/m-ubicacion.component';
import { Subscription } from 'rxjs';
import { SidepanelsService } from '../../services/sidepanels.service';

declare const $;

@Component({
  selector: 'app-distanciamoviles',
  templateUrl: './distanciamoviles.component.html',
  styleUrls: ['./distanciamoviles.component.scss']
})
export class DistanciamovilesComponent implements OnInit {

  @ViewChild(TablaComponent) tabla:TablaComponent;
  @ViewChild(MUbicacionComponent) mapaPop:MUbicacionComponent;

  //Datos de Lenguaje del DateTime Picker
  fechalang:any;  
  //Valores Fecha Desde / Hasta
  filtro:any = {
    fdesde: '',
    fhasta: '',
    velocidad: environment.velLimit //Velocidad default
  };

  modalRef: BsModalRef;
  tableData:any;

  configTable:any = {
    //Nombre del archivo a Exportar
    filename : "Distancia entre Móviles",
    //Usuario Genero PDF
    user_g : "",
    //Totales top
    headerPdf : ``,
    //Link endPoint
    endpoint : `informe-distancientremoviles.json`,
    //Configuración de columnas    
    t_columns : [
      { 
        name: 'fecha',
        data: 'fecha',
        title: "Fecha",
        width: "8%",
        className: "dt-center"
      },
      { 
        name: 'nombre',
        data: 'nombre',
        title: "Nombre",
        width: "13%"
      },
      { 
        name: 'sentido',
        data: 'sentido',
        title: "Sentido",
        width: "3%",
        className: "dt-center",
        render: function (data: any, type: any, row: any, meta) {
          var html = `<i class="fas fa-arrow-circle-up icoRow icoSentidos" style="transform: rotate(50deg);"></i>`;
          return html;
        }
      },
      { 
        name: 'movil_cercano',
        data: 'movil_cercano',
        title: "Móvil Cercano",
        width: "12%",
        className: "dt-center"
      },
      { 
        name: 'sentido_cercano',
        data: 'sentido_cercano',
        title: "Sentido Cercano",
        width: "8%",
        className: "dt-center",
        render: function (data: any, type: any, row: any, meta) {
          var html = `<i class="fas fa-arrow-circle-up icoRow icoSentidos" style="transform: rotate(50deg);"></i>`;
          return html;
        }
      },   
      { 
        name: 'distancia_moviles',
        data: 'distancia_moviles',
        title: "Distancia entre móviles (Mtrs)",
        width: "12%",
        className: "dt-center"
      },
      { 
        name: 'veren', 
        orderable: false,
        title: "Ver",
        width: "5%",
        render: function (data: any, type: any, row: any, meta) {
          let divClass = 'btn buttons-html5 color-dark-blue btn-accion btn-round';
          var html = `
          <button class='${divClass}' data-showmodal="${row.latitud};${row.longitud}" title="Ubicación ${row.nombre}">
            <i class="fas fa-map-marker-alt"></i> 
            <span class="label-action">GoogleMap</span>
          </button>`;
          return html;
        }
      }
    ],
    // Columnas a exportar
    exp_columns : [0,1,2,3,4,5,6],
    // Columa que debe llevar al header
    htarget     : 0,
    // Columna con botones de acciones
    actionbtn   : 6,
    // Agrupa o no (Deja un header arriba)  
    agrupar     : false
  } 

  mainClass:any;
  subscription: Subscription;

  constructor(
    private _rastreo:RastreoService,
    private activatedRoute:ActivatedRoute,
    private _panel:SidepanelsService,
    private modalService: BsModalService
  ) {
      //Trae la fecha inicial del día
      this.fechaInicial();
      //Trae la fecha con idioma español
      this.fechalang = this._rastreo.fechalang;
      this.setMainClass();
  }

  setMainClass(){
    let panel = this._panel.getPanelInfo();
    this.subscription = this._panel.getMainClass().subscribe(resp => { this.mainClass = resp; });
    this._panel.setPanelSizes();
  }
  
  ngOnInit(){
    this.getLista();
  }

  ngAfterViewInit(){    
  }

  //Fecha Inicial
  fechaInicial(){ 
    let fecha = this._rastreo.DiaEntero();
    this.filtro.fdesde = fecha.desde;
    this.filtro.fhasta = fecha.hasta; 
  }

  getTiempo(input, $event){
    let fecha = this._rastreo.DiaHoraActual();
    if(input=='fdesde'){ this.filtro.fdesde = fecha; }
    if(input=='fhasta'){ this.filtro.fhasta = fecha; }
  }

  setFiltros(){
    this.tabla.filtros = {
      fdesde: this.filtro.fdesde,
      fhasta: this.filtro.fhasta
    };
  }

  //Seteamos los parámetros de la tabla y traemos el contenido
  getLista(){    
    //Agrega el filtro de fechas
    this.setFiltros();   
    //Trae los móviles
    this.tabla.setData(this.configTable);    
  }

  showModal(data) {
    let map = data.split(";");
    const initialState = {
      latitud: map[0],
      longitud: map[1]
    };
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false
    };    
    this.modalRef = this.modalService.show(
      MUbicacionComponent,
      Object.assign( {initialState}, config, { class: 'gray modal-lg' })
    );
    //this.modalRef.content.closeBtnName = 'Close';
  }

}
