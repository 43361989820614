//angular
import { Routes, RouterModule } from '@angular/router';
import { CheckoutMovilUsuarioComponent } from './usuario/components';
import { AuthGuard } from '../../auth/auth.guard';
import { CheckoutMovilItemComponent } from './item/components';
import { CheckoutMovilDocumentoComponent } from './documento/components';
import { CheckoutMovilHistorialComponent } from './historial/components';
import { CheckoutMovilItemPuntuacionTipoComponent, CheckoutMovilItemPuntuacionComponent } from './puntuacion/components';

const checkoutMovilRoutes: Routes = [
    {
        path: 'checkout/movil',
        children: [
            {
                path: 'usuario/lista',
                component: CheckoutMovilUsuarioComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'item/lista',
                component: CheckoutMovilItemComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'documento/lista',
                component: CheckoutMovilDocumentoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'historial/lista',
                component: CheckoutMovilHistorialComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'tipo/puntuacion/lista',
                component: CheckoutMovilItemPuntuacionTipoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'puntuacion/lista',
                component: CheckoutMovilItemPuntuacionComponent,
                canActivate: [AuthGuard]
            },
        ]
    }
];

export const checkoutMovilRouting = RouterModule.forChild(checkoutMovilRoutes);