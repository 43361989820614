import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from "@angular/http";
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { UltPosVeh } from "../models/v_ult_posicion.model";

@Injectable()
export class SidebarService {

  public baseUrl:any = environment.baseUrl;
  public baseUrl_local:any = environment.baseUrl_local;
  private smoviles = new BehaviorSubject(null);
	moviles = this.smoviles.asObservable();
	user:any;

  constructor(private http: Http){
		this.user = JSON.parse(localStorage.getItem('currentUser'));
	}

  //Trae listado de Flota
	fetchMenuFlota(){
		return this.http.get(`${this.baseUrl_local}/s-flota-menu.php`)
		.map( res => res.json() )
  }

  //Trae listado de Móviles
	fetchMenuMoviles(){
		return this.http.get(`${this.baseUrl_local}/s-moviles-menu.php`)
		.map( res => res.json() )
  }
  
  //Trae listado de Móviles
	fetchSideMoviles():Observable<UltPosVeh[]>{		
		let cod_cliente = this.user.cod_cliente;
		return this.http.get(`${this.baseUrl}/listar/getvehiculos/${cod_cliente}`)
		.map( res => res.json() )
  }

  setSideMoviles(){
		this.fetchSideMoviles().subscribe(
			res=> {				
					this.smoviles.next(res);
			},
			//Error
			err => { 
				//console.log(err); 
			},
			//Terminó de cargar
			() => {  }
		); 
	}
  
}
