
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import { Gtp } from '../../../../constants/gtp';
import { Constant } from '../../../../constants/constant';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class RecorridoService {

    public baseUrl: string = '';

    constructor(public http: Http,
        public gtp: Gtp,
        public constant: Constant) {
        this.baseUrl = environment.baseUrl;
    }
    /**
     * Función que llama al bk para obtener la lista de las posiciones del vehículo dado un rango de fecha desde - hasta y el código de vehículo en cuestión.
     * @param codVehiculo código del vehículo en cuestión
     */
    obtenerTodasLasPosicionesPorVehiculo (body: any, codVehiculo: any): Observable<any[]> {
        return this.http.post(`${this.baseUrl}mobile/listar/getposiciones/${codVehiculo}`, body, this.constant.options)
            .map(response => response.json());
    }
}