import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GooglemapComponent } from '../../../../shared/googlemap/googlemap.component';
import { MapaService } from '../../../../services/mapa.service';
import { SidepanelsService } from '../../../services/sidepanels.service';
import { InicioService } from '../../../inicio/services';
import { Gtp } from '../../../../constants/gtp';
import { AlertService } from '../../../../comunes/alerta/services';

@Component({
  selector: 'app-ver-flota',
  templateUrl: '../templates/verFlota.component.html',
  styleUrls: ['../templates/verFlota.component.scss']
})
export class VerFlotaComponent implements OnInit {

  @ViewChild(GooglemapComponent) googleMap: GooglemapComponent;

  mainClass: any;
  subscription: Subscription;

  constructor(
    public _mapa: MapaService,
    public vehiculosService: InicioService,
    public _panel: SidepanelsService,
    public alertService: AlertService,
    public gtp: Gtp
  ) {
    this.setMainClass();
    this.gtp.setTituloCabecera('Ver Toda la Flota')
  }

  setMainClass() {
    let panel = this._panel.getPanelInfo();
    this.subscription = this._panel.getMainClass().subscribe(resp => { this.mainClass = resp; });
    this._panel.setPanelSizes();
  }

  ngOnInit() {
    this.googleMap.verflota = true;
  }

  getFlota() {
    this.vehiculosService.obtenerTodosLosVehiculos(this.gtp.obtenerUsuarioActual().cod_cliente).subscribe(
      data => {
        this.googleMap.setMap(data);
        //Habilita ver flota
      },
      //Error
      error => { 
        this.alertService.error(JSON.stringify(error));
      },
      //Terminó de cargar
      () => { }
    );
  }

}