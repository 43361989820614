//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablaModule } from '../../../shared/infotabla/infoTabla.module';
import { AccesoSistemaComponent } from './components';
import { accesoSistemaRouting } from './accesoSistema.routing';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TablaModule,
        accesoSistemaRouting
    ],
    declarations: [
        AccesoSistemaComponent,
    ],
    providers: [

    ],
    exports: [
        AccesoSistemaComponent
    ]
})

export class AccesoSistemaModule { }