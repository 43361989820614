//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { controlFlotaRouting } from './controlFlota.routing';
import { VerFlotaComponent } from './verflota/components';
import { GoogleMapModule } from '../../shared/googlemap/googlemap.module';
import { DistanciaRecorridaComponent } from './distancia-recorrida/components';
import { DistanciaRecorridaService } from './distancia-recorrida/services';
import { GMapModule, CalendarModule } from 'primeng/primeng';
import { TablaModule } from '../../shared/infotabla/infoTabla.module';
import { ControlVelocidadesComponent } from './control-velocidades/components';
import { ControlVelocidadesService } from './control-velocidades/services';
import { MUbicacionModule } from '../../mapa/m-ubicacion/m-ubicacion.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        controlFlotaRouting,
        GoogleMapModule,
        GMapModule,
        CalendarModule,
        TablaModule,
        MUbicacionModule
    ],
    declarations: [
        VerFlotaComponent,
        DistanciaRecorridaComponent,
        ControlVelocidadesComponent
    ],
    providers: [
        DistanciaRecorridaService,
        ControlVelocidadesService
    ],
    exports: [
        VerFlotaComponent
    ]
})

export class ControlFlotaModule { }