import { Component, OnInit, ViewChild } from '@angular/core';
import { RastreoService } from '../../../../services/rastreo.service';
import { AlertService } from '../../../../comunes/alerta/services';
import { GooglemapComponent } from '../../../../shared/googlemap/googlemap.component';
import { Gtp } from '../../../../constants/gtp';
import { ActivatedRoute, Router } from '@angular/router';
import { TablaComponent } from '../../../../shared/infotabla/infotabla.component';
import { MUbicacionComponent } from '../../../../mapa/m-ubicacion/m-ubicacion.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare const $;

@Component({
    selector: 'app-paradas',
    templateUrl: '../templates/paradas.component.html'
})

export class ParadasComponent implements OnInit {

    @ViewChild(GooglemapComponent) googleMap: GooglemapComponent;
    @ViewChild(TablaComponent) tabla: TablaComponent;
    @ViewChild(MUbicacionComponent) mapaPop: MUbicacionComponent;
    modalRef: BsModalRef;
    //Datos de Lenguaje del DateTime Picker
    fechalang: any;
    // máxima fecha para la fecha desde
    maxFechaDesde = new Date();
    // máxima fecha para la fecha hasta
    maxFechaHasta = new Date();
    fechaHoraDesde;
    fechaHoraHasta;
    tiempoMin;
    //Valores Fecha Desde / Hasta y tiempo mínimo
    filtro: any = {
        fdesde: Date,
        fhasta: Date,
        tiempoMin: Date
    };
    codVehiculo: any;
    vehiculo: any;

    tableData: any;
    configTable: any = {
        //Nombre del archivo a Exportar
        filename: `Paradas de Móvil ${this.codVehiculo}`,
        //Usuario Genero PDF
        user_g: "",
        //Totales top
        headerPdf: ``,
        //Link endPoint
        //endpoint: `paradas/${this.codVehiculo}`,
        endpoint: `paradas`,
        //Configuración de columnas    
        t_columns: [
            {
                name: 'fdesde',
                data: 'fdesde',
                title: "Desde",
                width: "10%",
                className: "dt-center"
            },
            {
                name: 'fhasta',
                data: 'fhasta',
                title: "Hasta",
                width: "10%"
            },
            {
                name: 'calle',
                data: 'calle',
                title: "Calle",
                width: "20%",
                className: "dt-center"
            },
            {
                name: 'punto_cercano',
                data: 'punto_cercano',
                title: "Punto Cercano",
                width: "15%",
                className: "dt-center"
            },
            {
                name: 'recorrido_entre_puntos',
                data: 'recorrido_entre_puntos',
                title: "Recorrido Entre Puntos",
                width: "15%",
                className: "dt-center"
            },
            {
                name: 'recorrido_acumulado',
                data: 'recorrido_acumulado',
                title: "Recorrido Acumulado",
                width: "15%",
                className: "dt-center"
            },
            {
                name: 'duracion',
                data: 'duracion',
                title: "Duración",
                width: "6%",
                className: "dt-center"
            },
            {
                name: 'veren',
                orderable: false,
                title: "Ver",
                width: "9%",
                render: function (data: any, type: any, row: any, meta) {
                    localStorage.setItem(row.id, JSON.stringify(row));
                    let divClass = 'btn buttons-html5 color-dark-blue btn-accion btn-round';
                    var html = `
                <button class='${divClass}' data-showmodal="${row.latitud};${row.longitud};${row.id}" title="Ubicación">
                  <i class="fas fa-map-marker-alt"></i> 
                  <span class="label-action">GoogleMap</span>
                </button>`;
                    return html;
                }
            }
        ],
        // Columnas a exportar
        exp_columns: [0, 1, 2, 3, 4],
        // Columa que debe llevar al header
        htarget: null,
        // Columna con botones de acciones
        actionbtn: null,
        // Agrupa o no (Deja un header arriba)  
        agrupar: false
    }

    constructor(
        public _rastreo: RastreoService,
        public alertService: AlertService,
        public route: ActivatedRoute,
        public modalService: BsModalService,
        public router: Router,
        public gtp: Gtp) {
        //Trae la fecha inicial del día
        this.obtenerFechaInicial();
        //Trae la fecha con idioma español
        this.fechalang = this._rastreo.fechalang;
        this.vehiculo = JSON.parse(gtp.getItemLocalStorage('vehiculo'))
        //setea el título de la cabecera principal
        this.gtp.setTituloCabecera(`Paradas - Móvil - ${this.vehiculo.des_equipo_ratreo}`);
    }

    ngOnInit() {
        //se obtiene el código del vehículo
        this.codVehiculo = this.route.snapshot.paramMap.get('id');
        this.obtenerTodasLasParadasPorFiltro();
    }

    /**
     * Función para obtener la lista con filtro y paginado de paradas de un vehículo
     */
    obtenerTodasLasParadasPorFiltro() {
        //seteamos los datos del filtro
        this.setFiltros();
        // obtenemos los móviles
        this.tabla.setData(this.configTable);
    }
    /**
     * Función para obtener la Fecha del día para utilizar en el filtro.
     */
    obtenerFechaInicial() {
        this.fechaHoraDesde = this._rastreo.DiaHoraActual();
        this.fechaHoraDesde.setHours(0, 0, 0, 0);
        this.fechaHoraHasta = this._rastreo.DiaHoraActual();
        this.tiempoMin = this._rastreo.DiaHoraActual();
        this.tiempoMin.setHours(0, 0, 30)
    }
    /**
     * Funcion para setear los valores de los filtros en la tabla para poderlos filtrar por eso datos.
     */
    setFiltros() {
        this.tabla.filtros = {
            fdesde: this.fechaHoraDesde,
            fhasta: this.fechaHoraHasta,
            tiempoMin: this.tiempoMin
        };
    }
    /**
     * Función para mostrar mediante un modal la posición del vehículo en el mapa con todos los datos.
     * @param data datos de posiciones
     */
    showModal(data) {
        let map = data.split(";");
        let obj = JSON.parse(localStorage.getItem(map[2]));
        let calle = obj.calle.split(",");
        // se construye el punto con todos los datos y detalles a mostrar en el mapa mediante el modal
        let punto = [{
            latitud: obj.latitud,
            longitud: obj.longitud,
            velocidad: obj.velocidad,
            geoinversa: `${calle[0]} ${calle[1]}`,
            cod_equipo_rastreo: this.vehiculo.cod_equipo_rastreo,
            fecha_hora: obj.fhasta
        }]
        // configuración inicial de datos a enviar al componente Modal.
        const initialState = {
          title: this.vehiculo.des_equipo_ratreo,
          puntos: punto
        };
        // configuraciones propias del modal.
        const config = {
          animated: true,
          keyboard: true,
          backdrop: true,
          ignoreBackdropClick: false
        };
        // se instancia el modal a mostrar.
        this.modalRef = this.modalService.show(
          MUbicacionComponent,
          Object.assign({ initialState }, config, { class: 'gray modal-lg' })
        );
        this.modalRef.content.closeBtnName = 'Cerrar';
      }
}
