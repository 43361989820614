//angular
import { Routes, RouterModule } from '@angular/router';
//components
import { VerFlotaComponent } from './verflota/components';
import { AuthGuard } from '../../auth/auth.guard';
import { DistanciaRecorridaComponent } from './distancia-recorrida/components';
import { ControlVelocidadesComponent } from './control-velocidades/components';

const controlFlotaRoutes: Routes = [
    {
        path: 'control/flota',
        children: [
            {
                path: 'ver',
                component: VerFlotaComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'distancia/recorrida',
                component: DistanciaRecorridaComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'velocidad',
                component: ControlVelocidadesComponent,
                canActivate: [AuthGuard]
            },
        ]
    }
];

export const controlFlotaRouting = RouterModule.forChild(controlFlotaRoutes);